<template>
	<svg
		width="74"
		height="74"
		viewBox="0 0 74 74"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M45.9607 20.6162C44.5585 20.6162 42.904 20.9431 40.9971 21.5971C39.0901 22.2513 37.716 22.5786 36.8747 22.579C36.2203 22.579 34.8976 22.2891 32.9066 21.7093C30.9156 21.1296 29.219 20.8397 27.8168 20.8397C24.5264 20.8397 21.8016 22.2234 19.6422 24.9909C17.4829 27.757 16.4033 31.337 16.4033 35.7308C16.4118 38.1628 16.7706 40.5809 17.4689 42.9105C18.2373 45.456 19.3008 47.9029 20.6378 50.2013C22.0586 52.6878 23.4888 54.5526 24.9284 55.7958C26.3679 57.039 27.8168 57.6606 29.2751 57.6606C30.2472 57.6606 31.5138 57.3381 33.0749 56.693C34.636 56.0479 36.0055 55.7254 37.1832 55.7254C38.361 55.7254 39.8052 56.034 41.5158 56.6512C43.2264 57.2687 44.5678 57.5773 45.5401 57.5769C46.774 57.5769 48.0078 57.1095 49.2417 56.1745C50.4755 55.2395 51.7094 53.8279 52.9433 51.9396C53.7243 50.76 54.4314 49.5331 55.0606 48.2661C55.6263 47.1279 56.0999 45.9462 56.4768 44.7324C54.7755 44.2282 53.2611 43.0224 51.9338 41.115C50.6148 39.2356 49.9184 36.9898 49.9427 34.6938C49.9482 33.1363 50.2978 31.5992 50.9663 30.1925C51.6487 28.7247 53.0274 27.1123 55.1026 25.3554C53.7753 23.71 52.3732 22.5086 50.8963 21.7513C49.3662 20.9818 47.6732 20.5924 45.9607 20.6162ZM45.484 9.7916C42.1375 10.5571 39.7398 11.978 38.2909 14.0541C36.842 16.1291 36.0989 18.5874 36.0616 21.4287C37.5198 21.3542 38.6883 21.1205 39.5671 20.7275C40.5329 20.2734 41.4072 19.6462 42.1469 18.877C43.2985 17.757 44.2056 16.4107 44.8111 14.9227C45.3719 13.5019 45.6524 12.1934 45.6524 10.9971C45.6524 10.8103 45.6429 10.6235 45.6243 10.4357C45.5988 10.2169 45.5517 10.0012 45.484 9.7916ZM37 0C57.4346 0 74 16.5649 74 37.0005C74 57.435 57.4345 74 37 74C16.5655 74 0 57.435 0 37.0005C0 16.565 16.5654 0 37 0Z"
			fill="#415BF5"
		/>
	</svg>
</template>

<script>
export default {
	name: 'IOS',
};
</script>

<style></style>
