<template>
	<svg
		width="92"
		height="83"
		viewBox="0 0 92 83"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M38.3354 68.2296C40.5502 68.2296 42.3518 66.428 42.3518 64.2132V38.2266C42.3518 36.0118 40.5502 34.2095 38.3354 34.2095H27.9285C25.7137 34.2095 23.9121 36.0118 23.9121 38.2266V64.2132C23.9121 66.428 25.7137 68.2296 27.9285 68.2296H31.9292V70.7409C29.476 71.3665 27.6487 73.5763 27.6487 76.2215C27.6487 79.3675 30.1966 81.8959 33.3231 81.8959C35.4115 81.8959 37.2199 80.7491 38.205 79.0645H52.4153C53.1606 81.3678 55.3035 82.9958 57.8084 82.9958C60.9365 82.9958 63.4822 80.4502 63.4822 77.3214C63.4822 74.6759 61.6547 72.4661 59.2017 71.8406V68.2295H63.0153C65.23 68.2295 67.0316 66.4279 67.0316 64.2131V38.2266C67.0316 36.0118 65.23 34.2095 63.0153 34.2095H52.6076C50.3929 34.2095 48.5913 36.0118 48.5913 38.2266V64.2132C48.5913 66.428 50.3929 68.2296 52.6076 68.2296H56.4137V71.8411C54.2925 72.3823 52.6462 74.1057 52.2395 76.2767H38.9921C38.9922 76.2579 38.9975 76.2405 38.9975 76.2215C38.9975 73.5763 37.1702 71.3665 34.7171 70.7409V68.2296H38.3354ZM52.6076 65.4417C51.9304 65.4417 51.3791 64.8905 51.3791 64.2132V38.2266C51.3791 37.5486 51.9304 36.9974 52.6076 36.9974H63.0153C63.6925 36.9974 64.2438 37.5486 64.2438 38.2266V64.2132C64.2438 64.8905 63.6925 65.4417 63.0153 65.4417H52.6076ZM57.8084 74.435C59.3997 74.435 60.6943 75.7302 60.6943 77.3215C60.6943 78.94 59.427 80.208 57.8084 80.208C56.2306 80.208 54.9219 78.9065 54.9219 77.3215C54.9217 75.7302 56.217 74.435 57.8084 74.435ZM36.2098 76.2217C36.2098 77.8402 34.9418 79.1082 33.3233 79.1082C31.6967 79.1082 30.4368 77.7677 30.4368 76.2217C30.4368 74.6304 31.732 73.3352 33.3233 73.3352C34.9146 73.3352 36.2098 74.6304 36.2098 76.2217ZM27.9285 65.4417C27.2513 65.4417 26.7 64.8905 26.7 64.2132V38.2266C26.7 37.5486 27.2513 36.9974 27.9285 36.9974H38.3354C39.0126 36.9974 39.5639 37.5486 39.5639 38.2266V64.2132C39.5639 64.8905 39.0126 65.4417 38.3354 65.4417H27.9285Z"
			fill="#415BF5"
		/>
		<path
			d="M60.5689 39.5942H55.0536C54.2838 39.5942 53.6597 40.2184 53.6597 40.9882C53.6597 41.758 54.2838 42.3821 55.0536 42.3821H60.5689C61.3387 42.3821 61.9628 41.758 61.9628 40.9882C61.9628 40.2184 61.3388 39.5942 60.5689 39.5942Z"
			fill="#31C6B4"
		/>
		<path
			d="M60.5689 45.4438H55.0536C54.2838 45.4438 53.6597 46.068 53.6597 46.8378C53.6597 47.6076 54.2838 48.2317 55.0536 48.2317H60.5689C61.3387 48.2317 61.9628 47.6076 61.9628 46.8378C61.9628 46.068 61.3388 45.4438 60.5689 45.4438Z"
			fill="#31C6B4"
		/>
		<path
			d="M35.8892 39.5942H30.3747C29.6049 39.5942 28.9807 40.2184 28.9807 40.9882C28.9807 41.758 29.6049 42.3821 30.3747 42.3821H35.8892C36.659 42.3821 37.2831 41.758 37.2831 40.9882C37.2831 40.2184 36.659 39.5942 35.8892 39.5942Z"
			fill="#31C6B4"
		/>
		<path
			d="M35.8892 45.4438H30.3747C29.6049 45.4438 28.9807 46.068 28.9807 46.8378C28.9807 47.6076 29.6049 48.2317 30.3747 48.2317H35.8892C36.659 48.2317 37.2831 47.6076 37.2831 46.8378C37.2831 46.068 36.659 45.4438 35.8892 45.4438Z"
			fill="#31C6B4"
		/>
		<path
			d="M78.8154 30.6265C81.8592 17.5634 67.0226 7.22574 55.7398 14.4566C52.7839 5.84933 44.6973 0 35.4148 0C19.3788 0 9.45214 16.6106 15.7908 30.4468C7.60898 30.0808 0 36.7648 0 45.729C0 54.1567 6.84652 61.0127 15.2619 61.0127C16.0317 61.0127 16.6559 60.3885 16.6559 59.6187C16.6559 58.8489 16.0317 58.2248 15.2619 58.2248C8.3835 58.2248 2.78789 52.6191 2.78789 45.729C2.78789 37.5645 10.4965 31.7302 17.9864 33.5613C18.5337 33.6995 19.1143 33.4844 19.4464 33.0257C19.7786 32.5663 19.8003 31.9523 19.5008 31.4712C11.7369 18.9953 20.6813 2.78789 35.4148 2.78789C44.0976 2.78789 51.5778 8.66654 53.6048 17.0833C53.7178 17.5529 54.0649 17.9299 54.5237 18.081C54.9818 18.2328 55.4861 18.1362 55.8549 17.8257C65.4991 9.74927 79.8537 19.6293 75.6832 31.4173C75.5342 31.8399 75.5968 32.309 75.8527 32.6771C76.108 33.0454 76.5252 33.2679 76.9737 33.2761C83.7222 33.3926 89.2121 38.9785 89.2121 45.729C89.2121 52.6191 83.6159 58.2248 76.7375 58.2248C75.9677 58.2248 75.3435 58.8489 75.3435 59.6187C75.3435 60.3885 75.9677 61.0127 76.7375 61.0127C85.1535 61.0127 92 54.1567 92 45.729C92 38.0746 86.2323 31.6434 78.8154 30.6265Z"
			fill="#415BF5"
		/>
	</svg>
</template>

<script>
export default {
	name: 'PrivateServer',
};
</script>

<style></style>
