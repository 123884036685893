import { useStore } from 'vuex';
import { computed } from 'vue';

const LOCALES = {
	EN: 'EN',
	RU: 'RU',
};

const availableLocales = Object.values(LOCALES);

const state = {
	locale: LOCALES.RU,
};

const mutations = {
	setLocalization(state, locale) {
		if (typeof locale === 'string' && availableLocales.includes(locale)) {
			state.locale = locale;
		}
	},
};

export const useLocalizationStore = () => {
	const { state, commit } = useStore();

	const currentLocale = computed(() => state.LocalizationStore.locale);

	const setLocalization = (locale) => {
		commit('LocalizationStore/setLocalization', locale);
	};

	return {
		locale: currentLocale.value,
		isEn: computed(() => currentLocale.value === LOCALES.EN),
		isRu: computed(() => currentLocale.value === LOCALES.RU),
		setLocalization,
	};
};

export const LocalizationStore = {
	namespaced: true,
	state,
	mutations,
};
