function directive(e, el, binding) {
	const handler =
		typeof binding.value === 'function' ? binding.value : binding.value.handler;

	if (!e) return;

	const elements = (
		(typeof binding.value === 'object' && binding.value.include) ||
		(() => [])
	)();

	elements.push(el);

	if (!elements.some((el) => el.contains(e.target))) {
		setTimeout(() => {
			handler && handler(e);
		}, 0);
	}
}

export const ClickOutside = {
	beforeMount(el, binding) {
		const onClick = (e) => directive(e, el, binding);

		document.body.addEventListener('click', onClick, true);
		el._clickOutside = onClick;
	},

	beforeUnmount(el) {
		if (!el._clickOutside) return;

		document.body.removeEventListener('click', el._clickOutside, true);
		delete el._clickOutside;
	},
};

export const VFocus = {
	mounted(el) {
		const input = el.getElementsByTagName('INPUT')[0];
		input && input.focus();
	},
};
