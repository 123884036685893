<template>
	<transition name="slide-fade-top">
		<div v-if="open" class="quick-register-form">
			<div class="form">
				<InputField
					fullWidth
					v-focus
					placeholder="E-Mail"
					name="email"
					autocomplete="email"
					:disabled="
						authStore.state.loading || authStore.state.sessionId !== null
					"
					v-model="authStore.state.email"
					@keyup.enter="qickReg"
				>
					<template v-slot:button>
						<ButtonIcon
							loading
							icon="Arrow"
							:disabled="buttonIsDisabled || authStore.state.sessionId !== null"
							@click="qickReg"
						/>
					</template>
				</InputField>
			</div>
		</div>
	</transition>
</template>

<script>
import { computed, watch } from 'vue';
import { $emit } from '../../../helpers/eventHub';
import { useAuthStore } from '../../../store/modules/Auth';
export default {
	props: {
		open: Boolean,
	},
	setup() {
		const authStore = useAuthStore();

		const isEmail = (str) => {
			const re =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(str).toLowerCase());
		};

		const not = (val) => !val;
		const buttonIsDisabled = computed(() => {
			return authStore.state.loading || not(isEmail(authStore.state.email));
		});

		const qickReg = () => {
			authStore.auth();
		};

		watch(authStore.state, () => {
			if (authStore.state.sessionId !== null) {
				$emit('openFormOnSessionId');
			}
		});

		return {
			authStore,
			buttonIsDisabled,
			qickReg,
		};
	},
};
</script>

<style scoped lang="scss">
.quick-register-form {
	width: 396px;
	height: 93px;
	border-radius: 5px;
	background-color: #fff;
	position: absolute;
	left: 0;
	top: 70px;
  z-index: 99;
	filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
	@media (max-width: 320px) {
		width: 290px;
	}
	@media (min-width: 321px) and (max-width: 375px) {
		width: 345px;
	}
	@media (min-width: 376px) and (max-width: 414px) {
		width: 386px;
	}
	.form {
		width: 100%;
		height: 100%;
		padding: 0 20.5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&:before {
		content: '';
		display: block;
		position: inherit;
		width: 25px;
		height: 25px;
		background-color: #fff;
		border-radius: 3px;
		left: 83px;
		transform: translateY(-40%) rotate(-45deg);
	}
}

.slide-fade-top-enter-active,
.slide-fade-top-leave-active {
	transition: all 0.3s ease-out;
}

.slide-fade-top-enter-from,
.slide-fade-top-leave-to {
	opacity: 0;
}
</style>
