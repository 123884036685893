<template>
	<svg
		width="161"
		height="39"
		viewBox="0 0 161 39"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M147.743 11.7691C146.697 16.0354 145.701 20.0902 144.706 24.1469C144.789 24.1933 144.872 24.2396 144.955 24.284C145.279 24.0159 145.606 23.7519 145.924 23.4778C147.275 22.3191 148.676 21.2086 149.957 19.9773C150.852 19.1168 151.804 18.8165 153.033 18.8548C155.654 18.9354 158.28 18.879 161 19.0704C160.727 19.2841 160.457 19.5037 160.18 19.7113C157.482 21.7145 154.794 23.7338 152.071 25.7047C151.449 26.1561 151.387 26.527 151.737 27.19C153.319 30.1766 154.85 33.1915 156.396 36.1983C156.578 36.5529 156.746 36.9117 156.973 37.3752C156.629 37.4215 156.378 37.484 156.126 37.484C153.665 37.4921 151.204 37.4638 148.743 37.5042C148.044 37.5162 147.736 37.2321 147.477 36.6336C146.63 34.6727 145.727 32.736 144.785 30.6603C143.669 31.412 142.523 31.8936 142.324 33.4071C142.174 34.5578 141.793 35.6823 141.457 36.8008C141.378 37.0628 141.074 37.4477 140.868 37.4518C138.279 37.4961 135.69 37.48 132.992 37.48C133.014 37.1253 132.994 36.8673 133.053 36.6295C135.05 28.6269 137.051 20.6242 139.079 12.6296C139.16 12.3092 139.506 11.8134 139.739 11.8074C142.334 11.7469 144.923 11.7691 147.743 11.7691Z"
			fill="#415BF5"
		/>
		<path
			d="M15.834 35.0114C16.6362 33.9393 17.3168 33.0344 17.9893 32.1235C22.794 25.6263 27.6068 19.1351 32.3872 12.6197C32.8591 11.9768 33.3513 11.6987 34.1454 11.7531C35.1075 11.8176 36.0758 11.7692 37.1676 11.7692C36.4525 14.5765 35.7861 17.2205 35.1035 19.8585C33.6896 25.3219 32.2373 30.7733 30.874 36.2488C30.6188 37.2725 30.1833 37.6635 29.1604 37.5043C28.7755 37.4438 28.3724 37.4942 27.8033 37.4942C29.9504 29.6749 32.057 22.0008 34.1636 14.3266C34.0806 14.2803 33.9975 14.2319 33.9124 14.1855C33.6613 14.506 33.404 14.8183 33.163 15.1448C27.8802 22.289 22.5975 29.4351 17.3147 36.5793C16.7273 37.3733 14.9671 37.8852 14.0941 37.4317C13.8409 37.3007 13.6849 36.8554 13.616 36.5249C12.111 29.4734 10.6242 22.4179 9.12934 15.3625C9.02198 14.8586 8.87816 14.3609 8.75055 13.8611C8.66547 13.8611 8.57837 13.8611 8.4933 13.8611C8.20161 15.0037 7.90385 16.1464 7.62027 17.2911C6.05854 23.5767 4.47655 29.8583 2.95939 36.1561C2.71632 37.1677 2.34968 37.7139 1.22751 37.5043C0.877081 37.4378 0.504372 37.4942 0 37.4942C0.089126 37.0387 0.133689 36.7224 0.214713 36.414C2.27474 28.5323 4.34894 20.6526 6.38871 12.7648C6.58114 12.0191 6.89106 11.6382 7.68711 11.7692C7.75193 11.7793 7.8208 11.7712 7.88967 11.7712C10.8997 11.7652 10.8612 11.7733 11.4689 14.7458C12.8058 21.2874 14.1852 27.8209 15.5525 34.3564C15.5869 34.5055 15.6781 34.6466 15.834 35.0114Z"
			fill="#415BF5"
		/>
		<path
			d="M95.8936 18.6533C96.2845 17.027 96.639 15.5115 97.0117 14.0021C97.5667 11.7611 97.5728 11.7611 99.9326 11.7611C107.646 11.7611 115.361 11.7611 123.075 11.7611C123.44 11.7611 123.804 11.7611 124.317 11.7611C124.234 12.2166 124.189 12.5652 124.106 12.9058C123.725 14.4273 123.277 15.9327 122.968 17.4684C122.775 18.4176 122.38 18.7622 121.365 18.7541C115.74 18.7078 110.113 18.74 104.488 18.742C102.064 18.744 99.6368 18.744 97.2122 18.736C96.8172 18.734 96.4243 18.6876 95.8936 18.6533Z"
			fill="#415BF5"
		/>
		<path
			d="M99.823 37.482C100.449 34.971 101.028 32.6454 101.61 30.3198C102.211 27.9236 102.799 25.5214 103.441 23.1373C103.52 22.8431 103.909 22.4179 104.16 22.4138C107.024 22.3695 109.89 22.3957 112.756 22.4078C112.811 22.4078 112.864 22.4763 112.987 22.5589C112.169 25.8277 111.345 29.1247 110.518 32.4217C110.151 33.8787 109.803 35.3418 109.386 36.7848C109.307 37.0588 108.944 37.4478 108.709 37.4518C105.819 37.4982 102.924 37.482 99.823 37.482Z"
			fill="#415BF5"
		/>
		<path
			d="M120.575 37.4861C121.402 34.1669 122.171 31.0654 122.943 27.9659C123.346 26.3477 123.727 24.7213 124.181 23.1172C124.262 22.827 124.651 22.4179 124.904 22.4139C127.46 22.3615 130.016 22.3816 132.753 22.3816C132.567 23.226 132.417 23.9898 132.228 24.7435C131.24 28.6914 130.223 32.6333 129.269 36.5893C129.101 37.2886 128.811 37.5083 128.114 37.4982C125.663 37.4659 123.21 37.4861 120.575 37.4861Z"
			fill="#415BF5"
		/>
		<path
			d="M87.2947 18.4036C88.0786 18.4902 88.9942 18.5144 89.8753 18.7038C94.5058 19.7014 96.657 22.7586 95.9946 27.3977C94.7529 36.0896 87.2319 39.3362 80.1019 37.5104C75.8198 36.4141 74.3998 32.712 74.7867 29.1047C75.3235 24.1068 78.7954 19.6873 84.6594 18.6555C85.4839 18.5084 86.3326 18.4922 87.2947 18.4036ZM83.8938 36.273C84.5703 36.2387 85.1132 36.2468 85.6459 36.1763C86.0753 36.1178 86.5007 35.9868 86.9139 35.8538C90.7139 34.6205 93.5153 30.5214 93.5254 26.1986C93.5315 23.0548 91.907 20.9126 88.8139 20.3745C87.4568 20.1387 85.9761 20.179 84.621 20.4471C80.1181 21.3358 77.1182 26.0153 77.2944 30.5033C77.418 33.631 79.1215 35.4749 82.2227 36.1118C82.8121 36.2307 83.4259 36.2307 83.8938 36.273Z"
			fill="#415BF5"
		/>
		<path
			d="M60.2268 37.4901C59.3275 37.4901 58.6448 37.5728 57.9967 37.4579C57.6422 37.3954 57.2411 37.0609 57.0467 36.7404C55.3573 33.9654 53.7105 31.1662 52.0495 28.375C51.8652 28.0667 51.6667 27.7644 51.3932 27.3291C49.7505 28.4798 48.1806 29.556 46.6453 30.6765C46.4386 30.8276 46.3596 31.1944 46.2847 31.4766C45.8492 33.123 45.3813 34.7615 45.0309 36.4261C44.8486 37.2926 44.4556 37.6171 43.5927 37.5022C43.2058 37.4498 42.8047 37.4942 42.2234 37.4942C42.3976 36.7606 42.5252 36.1883 42.671 35.622C43.9715 30.5455 45.3104 25.4791 46.546 20.3865C46.8154 19.2741 47.2306 18.736 48.4116 18.9577C48.6992 19.0121 49.0051 18.9657 49.4649 18.9657C48.687 22.0209 47.9416 24.9551 47.1962 27.8894C47.2732 27.9277 47.3522 27.9639 47.4292 28.0022C47.7067 27.835 47.9943 27.6838 48.2596 27.5004C51.691 25.1365 55.177 22.8431 58.5233 20.3663C60.0931 19.2056 61.6731 18.6755 63.6683 19.0685C63.4333 19.2579 63.2328 19.4454 63.0059 19.5985C60.1114 21.5574 57.2269 23.5323 54.304 25.4489C53.6194 25.8983 53.5181 26.2389 53.9516 26.9482C55.8394 30.0336 57.6665 33.1533 59.5158 36.2608C59.7225 36.5994 59.9149 36.944 60.2268 37.4901Z"
			fill="#415BF5"
		/>
		<path
			d="M76.8449 18.8327C76.7295 19.3386 76.7234 19.8041 76.5168 20.1507C76.3385 20.449 75.9152 20.8198 75.6235 20.8017C70.3995 20.4792 67.0654 23.8729 66.0566 28.4778C65.4712 31.1461 64.6651 33.7679 64.027 36.4261C63.8244 37.2664 63.4538 37.6272 62.5828 37.5022C62.1918 37.4458 61.7867 37.4921 61.2114 37.4921C61.418 36.6236 61.5841 35.8638 61.7806 35.1081C63.0324 30.2976 64.2944 25.4912 65.5462 20.6807C66.0263 18.8327 66.0182 18.8307 68.2078 19.0464C67.8878 20.3744 67.5718 21.6904 67.2558 23.0043C67.3287 23.0588 67.4016 23.1132 67.4746 23.1676C69.6865 19.8001 72.7634 18.3531 76.8449 18.8327Z"
			fill="#415BF5"
		/>
		<path
			d="M125.26 18.7058C125.832 16.4688 126.332 14.4314 126.893 12.4101C126.966 12.1501 127.351 11.7974 127.598 11.7934C130.154 11.7491 132.711 11.7652 135.437 11.7652C135.237 12.6378 135.07 13.3996 134.882 14.1573C134.56 15.4531 134.246 16.753 133.875 18.0347C133.8 18.2927 133.49 18.6756 133.284 18.6776C130.665 18.7219 128.044 18.7058 125.26 18.7058Z"
			fill="#415BF5"
		/>
		<path
			d="M35.3931 37.4922C36.6267 32.7442 37.7995 28.232 38.9703 23.7198C39.2883 22.4945 39.6468 21.2753 39.9041 20.0379C40.0762 19.2056 40.455 18.8247 41.3341 18.9577C41.721 19.0162 42.1261 18.9678 42.6649 18.9678C42.5819 19.4071 42.5414 19.7215 42.4644 20.0278C41.0708 25.4167 39.6387 30.7975 38.3018 36.2004C38.0385 37.2624 37.5969 37.6756 36.5375 37.5023C36.2215 37.4479 35.8893 37.4922 35.3931 37.4922Z"
			fill="#415BF5"
		/>
		<path
			d="M54.8226 15.6345C50.0382 16.7791 46.0255 15.7776 42.7096 12.4241C39.6408 9.32264 38.6908 5.57624 39.5659 1.3623C41.077 9.73375 46.1126 14.4072 54.8226 15.6345Z"
			fill="#31C6B4"
		/>
		<path
			d="M55.9205 11.3238C52.6532 12.2408 49.6999 11.6624 47.1376 9.5524C44.1336 7.07764 43.1148 3.84111 43.8055 0C45.1282 6.64436 48.9464 10.6326 55.9205 11.3238Z"
			fill="#31C6B4"
		/>
	</svg>
</template>

<script>
export default {
	name: 'Microt',
};
</script>

<style></style>
