<template>
	<button
		:class="{
			[variant]: !!variant,
			btn: true,
			loading,
			'full-width': fullWidth,
		}"
		:style="{
			width: `${width}px`,
			height: `${height}px`,
			'border-radius': `${borderRadius}px`,
		}"
		v-bind="$attrs"
	>
		<LoaderIcon v-if="loading" class="btn_loader-icon" />
		<span
			v-else
			:style="{
				'font-size': `${fontSize}px`,
				'font-weight': fontWeight,
				color,
			}"
		>
			<slot />
		</span>
	</button>
</template>
<script>
export default {
	name: 'Button',
	props: {
		variant: {
			type: String,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		width: [String, Number],
		height: [String, Number],
		fontSize: [String, Number],
		fontWeight: [String, Number],
		borderRadius: [String, Number],
		color: String,
	},
};
</script>
<style lang="scss">
.btn {
	&_loader-icon path {
		fill: #c5c5c5;
	}
	&.primary {
		.btn_loader-icon path {
			fill: #fff;
		}
	}
	&.secondary {
		.btn_loader-icon path {
			fill: #31c6b4;
		}
	}
	&:disabled {
		.btn_loader-icon path {
			fill: #c5c5c5;
		}
	}
}
</style>
<style lang="scss">
.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 150px;
	height: 50px;
	border: 1px solid #c5c5c5;
	background-color: #fff;
	border-radius: 6px;
	cursor: pointer;
	outline: none;
	transition: 0.2s ease;
	margin: 0;
	padding: 0 8px;
	&.full-width {
		width: 100%;
	}
	span {
		font-size: 16px;
		font-weight: 500;
    white-space: nowrap;
	}
	&.primary {
		background-color: #31c6b4;
		border-color: #31c6b4;
		span {
			color: #fff;
		}
	}
	&.white {
		background: none;
		border-color: #fff;
		span {
			color: #fff;
		}
	}
	&.secondary {
		border-color: #31c6b4;
		background-color: transparent;
		span {
			color: #31c6b4;
		}
	}
	span {
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 500;
		color: #6b6b6b;
		transition: 0.2s ease;
	}
	&:hover:not(.loading):not(:disabled) {
		background-color: #31c6b4;
		border-color: #31c6b4;
		box-shadow: 5px 8px 30px 0px rgba(49, 198, 180, 0.4);
		span {
			color: #fff;
		}
	}
	&:active:not(.loading):not(:disabled) {
		background-color: #27b3a2;
		box-shadow: none;
	}
	&:disabled,
	&.loading {
		cursor: not-allowed;
	}
	&:disabled {
		border: none;
		background-color: #f5f5f5;
		span {
			color: #c5c5c5;
		}
	}
}
.btn-group {
	display: flex;
	.btn {
		&:first-child {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
		&:not(:last-child) {
			margin-right: 1px;
		}
		&:not(:first-child):not(:last-child) {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
		&:last-child {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}
}
</style>
