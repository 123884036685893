<template>
	<svg
		:width="size"
		:height="size"
		viewBox="0 0 35 35"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M17.4992 0C10.8645 0 5.46793 5.39656 5.46793 12.0312C5.46793 18.6659 10.8645 24.0625 17.4992 24.0625C24.1317 24.0625 29.5304 18.6659 29.5304 12.0312C29.5304 5.39656 24.1317 0 17.4992 0ZM23.8232 10.3294L21.7451 12.9325L21.9726 16.3341C21.9989 16.7125 21.8261 17.0778 21.5132 17.2987C21.3229 17.4322 21.102 17.5 20.8789 17.5C20.7345 17.5 20.5879 17.4716 20.4523 17.4125L17.4992 16.1591L14.5482 17.4125C14.1982 17.5613 13.7957 17.5197 13.4873 17.2987C13.1767 17.0778 13.0039 16.7125 13.0279 16.3341L13.2554 12.9325L11.1773 10.3294C10.9454 10.0406 10.8776 9.65344 10.9936 9.30125C11.1095 8.94906 11.3961 8.68219 11.7548 8.58813L14.8567 7.77656L16.5585 4.91094C16.9523 4.24594 18.046 4.24594 18.4376 4.91094L20.1395 7.77656L23.2435 8.58813C23.6001 8.68219 23.8867 8.95125 24.0048 9.30125C24.1229 9.65125 24.0529 10.0384 23.8232 10.3294Z"
			fill="#ADADAD"
		/>
		<path
			d="M5.1945 19.1254L0.145753 27.8907C-0.0751841 28.2757 -0.0423716 28.7547 0.226691 29.1047C0.495753 29.4547 0.948566 29.6122 1.37732 29.4941L7.79982 27.7682L9.50825 34.1907C9.622 34.6172 9.98294 34.9344 10.4204 34.9935C10.4686 34.9979 10.5189 35.0001 10.5648 35.0001C10.952 35.0001 11.3151 34.7944 11.512 34.4532L16.2873 26.1888C11.5426 25.7863 7.46513 23.0454 5.1945 19.1254Z"
			fill="#ADADAD"
		/>
		<path
			d="M34.8548 27.8907L29.8039 19.1254C27.5354 23.0454 23.4557 25.7863 18.7111 26.1888L23.4864 34.4532C23.6832 34.7944 24.0464 35.0001 24.4336 35.0001C24.4795 35.0001 24.5298 34.9979 24.5757 34.9913C25.0154 34.9322 25.3742 34.6151 25.4901 34.1885L27.1986 27.766L33.6211 29.4919C34.0498 29.6101 34.5004 29.4526 34.7717 29.1026C35.0429 28.7547 35.0736 28.2757 34.8548 27.8907Z"
			fill="#ADADAD"
		/>
	</svg>
</template>
<script>
export default {
	name: 'Medal',
	inheritAttrs: false,
	props: {
		size: {
			type: String,
			default: '30',
		},
	},
};
</script>
