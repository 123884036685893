<template>
	<svg
		width="53"
		height="53"
		viewBox="0 0 53 53"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0)">
			<path
				d="M21.0509 26.2356H31.5568V34.1151H21.0509V26.2356Z"
				fill="#3C53DC"
			/>
			<path
				d="M28.9304 20.9829C28.9304 19.5326 27.7544 18.3566 26.3037 18.3566C24.8533 18.3566 23.6773 19.5322 23.6773 20.9829V23.6093H28.93V20.9829H28.9304Z"
				fill="#3C53DC"
			/>
			<path
				d="M48.6284 7.85067C40.9888 7.85067 30.9372 5.27317 27.3543 0.496692C26.8776 -0.0836957 26.0209 -0.167409 25.4409 0.308837C25.3724 0.365314 25.3095 0.428199 25.253 0.496692C21.6702 5.27237 11.6181 7.85067 3.97897 7.85067C3.25399 7.85067 2.66599 8.43867 2.66599 9.16406V26.2356C2.66599 40.676 15.2058 49.381 25.9436 52.4488C26.1787 52.5173 26.4286 52.5173 26.6642 52.4488C37.4019 49.381 49.9417 40.676 49.9417 26.2356V9.16406C49.9417 8.43867 49.3538 7.85067 48.6284 7.85067ZM34.1832 35.4281C34.1832 36.1535 33.5952 36.7415 32.8702 36.7415H19.7376C19.0126 36.7415 18.4246 36.1535 18.4246 35.4281V24.9226C18.4246 24.1973 19.0126 23.6093 19.7376 23.6093H21.0509V20.9829C21.0509 18.0818 23.4025 15.7302 26.3037 15.7302C29.2048 15.7302 31.5568 18.0818 31.5568 20.9829V23.6093H32.8698C33.5952 23.6093 34.1828 24.1973 34.1828 24.9226V35.4281H34.1832Z"
				fill="#3C53DC"
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="52.5" height="52.5" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
