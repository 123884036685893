<template>
	<svg
		width="17"
		height="9"
		viewBox="0 0 17 9"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		v-bind="$attrs"
	>
		<path
			d="M8.9665 8.81012L16.8078 1.10229C17.0645 0.849749 17.0641 0.440885 16.8064 0.18877C16.5488 -0.06315 16.1315 -0.062499 15.8745 0.190072L8.49997 7.43911L1.12545 0.189811C0.868458 -0.0627269 0.451395 -0.0633779 0.193741 0.188509C0.0645809 0.314892 0 0.480463 0 0.646033C0 0.81118 0.0641479 0.9761 0.192411 1.10225L8.03347 8.81012C8.15692 8.93175 8.32492 9 8.49997 9C8.67501 9 8.84282 8.93155 8.9665 8.81012Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	name: 'ArrowDropdown',
	inheritAttrs: false,
	props: {
		color: {
			type: String,
			default: '#6B6B6B',
		},
	},
};
</script>
<style scoped lang="scss"></style>
