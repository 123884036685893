<template>
	<div class="col">
		<slot />
	</div>
</template>
<style scoped lang="scss">
.col {
	flex: 1 0 0%;
	padding: 0 15px;
	min-height: 1px;
	&.f-25 {
		flex: 0 0 25%;
	}
	&.media-f-25 {
		@media (min-width: 1025px) {
			flex: 0 0 25% !important;
		}
	}
	&.f-50 {
		flex: 0 0 100%;
	}
	@media (min-width: 319px) and (max-width: 568px) {
		flex: 0 0 100% !important;
	}
	&.media-f-50 {
		@media (min-width: 319px) and (max-width: 420px) {
			flex: 0 0 50% !important;
		}
	}
	&.media-f {
		@media (min-width: 319px) and (max-width: 666px) {
			flex: 0 0 50% !important;
		}
	}
}
</style>
