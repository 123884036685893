<template>
	<div class="header-control">
		<transition name="slide-fade">
			<div v-if="mobileMenuIsOpen" class="mobile-menu">
				<div class="head">
					<Logo />
					<button @click="toggleMobileMenuState">
						<svg
							width="33"
							height="33"
							viewBox="0 0 33 33"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="16.5" cy="16.5" r="16.5" fill="#F5F5F5" />
							<path
								d="M23.0124 10.58L10.58 23.0124C10.268 23.3243 9.76169 23.3243 9.44975 23.0124C9.13781 22.7004 9.13781 22.1941 9.44975 21.8822L21.8822 9.44976C22.1941 9.13782 22.7004 9.13782 23.0124 9.44976C23.3243 9.7617 23.3243 10.268 23.0124 10.58Z"
								fill="#415BF5"
							/>
							<path
								d="M10.5799 9.44991L23.0123 21.8823C23.3242 22.1943 23.3242 22.7006 23.0123 23.0125C22.7003 23.3245 22.194 23.3245 21.882 23.0125L9.44964 10.5801C9.1377 10.2682 9.1377 9.76185 9.44964 9.44991C9.76158 9.13797 10.2679 9.13797 10.5799 9.44991Z"
								fill="#415BF5"
							/>
						</svg>
					</button>
				</div>

				<div class="mobile-menu-list">
					<ul>
						<li>
							<a href="#wiv" @click="toggleMobileMenuState">{{
								translate.nav[0]
							}}</a>
						</li>
						<li>
							<a href="#capabilities" @click="toggleMobileMenuState">{{
								translate.nav[1]
							}}</a>
						</li>
						<li>
							<a href="#pricing" @click="toggleMobileMenuState">{{
								translate.nav[2]
							}}</a>
						</li>
						<!--<li>
              <div class="locale">
                <button
                  :class="{ active: isEn }"
                  :disabled="isEn"
                  @click="setLocalization('en')"
                >
                  en
                </button>
                <div class="sep" />
                <button
                  :class="{ active: isRu }"
                  :disabled="isRu"
                  @click="setLocalization('ru')"
                >
                  ru
                </button>
              </div>
            </li>-->
					</ul>
					<Button
						v-if="isAuth"
						variant="white"
						fullWidth
						height="70"
						class="mt-70"
						@click="logout"
					>
						{{ translate.button2 }}
					</Button>
					<Button
						v-if="isAuth"
						variant="white"
						fullWidth
						height="70"
						class="mt-70"
						style="margin-top: 15px"
						@click="toPersonalArea"
					>
						{{ translate.button11 }}
					</Button>
					<Button
						v-if="!isAuth"
						variant="white"
						fullWidth
						height="70"
						class="mt-70"
						@click="handleOpenLoginFormWithCloseMenu"
					>
						{{ translate.button1 }}
					</Button>
				</div>
			</div>
		</transition>
		<div tabindex="0" class="locale__wrapper noselect" ref="locales_wrapper">
			<div class="locale__current">
				{{ '' + currentLocale }}
			</div>
			<div class="locale__triangle locale__box--hidden"></div>
			<div class="locale__box locale__box--hidden">
				<div
					v-for="locale in locales"
					:key="'locale' + locale"
					class="locale"
					:class="currentLocale === locale ? 'locale--hidden' : ''"
					@click="setLocalization(locale, currentLocale === locale)"
				>
					{{ locale }}
				</div>
			</div>
		</div>
		<div class="login-box">
			<Button
				v-if="isNotAuth"
				variant="primary"
				width="80"
				height="30"
				fontSize="14"
				fontWeight="400"
				borderRadius="4"
				@click="handleToggleStateForm"
			>
				{{ translate.button1 }}
			</Button>
			<div v-if="isAuth" class="btn-group media">
				<Button
					variant="primary"
					width="80"
					height="30"
					fontSize="14"
					fontWeight="400"
					borderRadius="4"
					@click="toPersonalArea"
				>
					{{ translate.button3 }}
				</Button>
				<Button
					variant="primary"
					width="80"
					height="30"
					fontSize="14"
					fontWeight="400"
					borderRadius="4"
					@click="logout"
				>
					{{ translate.button2 }}
				</Button>
			</div>
			<button class="mobile-menu-btn" @click="toggleMobileMenuState">
				<svg
					width="27"
					height="19"
					viewBox="0 0 27 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M25.2117 10.5963H1.09616C0.491077 10.5963 0 10.1052 0 9.50015C0 8.89507 0.491077 8.40399 1.09616 8.40399H25.2117C25.8167 8.40399 26.3078 8.89507 26.3078 9.50015C26.3078 10.1052 25.8167 10.5963 25.2117 10.5963Z"
						fill="white"
					/>
					<path
						d="M25.2117 2.19232H1.09616C0.491077 2.19232 0 1.70124 0 1.09616C0 0.491077 0.491077 0 1.09616 0H25.2117C25.8167 0 26.3078 0.491077 26.3078 1.09616C26.3078 1.70124 25.8167 2.19232 25.2117 2.19232Z"
						fill="white"
					/>
					<path
						d="M25.2117 19H1.09616C0.491077 19 0 18.5089 0 17.9038C0 17.2988 0.491077 16.8077 1.09616 16.8077H25.2117C25.8167 16.8077 26.3078 17.2988 26.3078 17.9038C26.3078 18.5089 25.8167 19 25.2117 19Z"
						fill="white"
					/>
				</svg>
			</button>
			<transition v-if="isNotAuth" name="slide-fade-top">
				<HeaderLoginForm
					@onClose="handleToggleStateForm"
					:open="formIsOpen"
					v-click-outside="handleClickOutside"
				/>
			</transition>
		</div>
	</div>
</template>

<script>
import HeaderLoginForm from './HeaderLoginForm';
import { ref, watch, computed } from 'vue';
import { useAuthStore } from '../../../store/use';
import { ratesStore } from '../../../store/modules/RatesStore';
import { $on } from '../../../helpers/eventHub';
import Cookies from 'js-cookie';
import { AuthStore } from '@/store/modules/Auth';
const personalAreaUrl = process.env.VUE_APP_DVPN_LK;

export default {
	components: {
		HeaderLoginForm,
	},
	inject: ['translate'],
	setup() {
		const locales = window.locales;
		const localeActive = ref(false);
		let temp = Cookies.get('dvpn_auth_locale') || 'en';
		if (!window.locales.includes(temp)) {
			temp = 'en';
		}
		const currentLocale = ref(temp);
		const locales_wrapper = ref();

		const setLocalization = (code, run) => {
			if (!run) {
				currentLocale.value = code;
				locales_wrapper.value.blur();
				if (isAuth.value) {
					AuthStore.actions.setLocale(code).finally(() => {
						const domain =
							process.env.VUE_APP_MODE === '0'
								? 'domain.com'
								: process.env.VUE_APP_MODE === '1'
								? 'dl-net.ru '
								: 'dubki-vpn.ru';
						Cookies.set('dvpn_auth_locale', code, {
							path: '/',
							domain: domain,
							expires: 365,
						});
					});
				} else {
					const domain =
						process.env.VUE_APP_MODE === '0'
							? 'domain.com'
							: process.env.VUE_APP_MODE === '1'
							? 'dl-net.ru '
							: 'dubki-vpn.ru';
					Cookies.set('dvpn_auth_locale', code, {
						path: '/',
						domain: domain,
						expires: 365,
					});
				}
			}
		};

		const { logout, isAuth, state: authState } = useAuthStore();

		const formIsOpen = ref(false);

		const mobileMenuIsOpen = ref(false);

		const handleToggleStateForm = () => {
			formIsOpen.value = !formIsOpen.value;
		};

		const handleClickOutside = () => {
			formIsOpen.value = false;
		};

		const toggleMobileMenuState = () => {
			mobileMenuIsOpen.value = !mobileMenuIsOpen.value;
		};

		const handleOpenLoginFormWithCloseMenu = () => {
			toggleMobileMenuState();
			handleToggleStateForm();
		};

		watch(ratesStore.state, (state) => {
			if (!isAuth.value && state.currentRate !== null) {
				handleToggleStateForm();
			}
		});

		const toPersonalArea = () => {
			window.location.href = personalAreaUrl;
		};

		const isNotAuth = computed(() => !isAuth.value);

		watch(isAuth, () => {
			formIsOpen.value = false;
		});

		$on('openFormOnSessionId', () => (formIsOpen.value = true));

		return {
			locales,
			setLocalization,
			localeActive,
			currentLocale,
			locales_wrapper,
			// isRu,
			// isEn,
			formIsOpen,
			handleClickOutside,
			handleToggleStateForm,
			toggleMobileMenuState,
			mobileMenuIsOpen,
			handleOpenLoginFormWithCloseMenu,
			authState,
			isAuth,
			isNotAuth,
			logout,
			toPersonalArea,
		};
	},
};
</script>

<style scoped lang="scss">
.btn-group {
	display: flex;
	@media (max-width: 1200px) {
		display: none;
	}
}
.header-control {
	flex: 0 1 auto;
	display: flex;
	.mobile-menu {
		display: none;
		width: 100%;
		height: 100%;
		position: fixed;
		overflow-y: auto;
		top: 0;
		left: 0;
		background: #415bf5;
		z-index: 99;
		padding: 35px 0;
		.mobile-menu-list {
			padding: 0 27px;
			margin-top: 60px;
			.mt-70 {
				margin-top: 70px;
			}
			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				li {
					a {
						font-size: 22px;
						color: #fff;
						font-weight: 400;
						text-decoration: none;
					}
					text-align: right;
					padding: 12px 0;
					border-bottom: 1px solid #b1bdff;
				}
			}
			.locale {
				display: flex;
				padding-top: 50px;
				justify-content: flex-end;
				margin-right: 0;
				button {
					font-size: 22px;
					font-weight: 400;
				}
				.sep {
					margin: 0 20px;
				}
			}
		}
		.head {
			padding: 0 27px;
			display: flex;
			justify-content: space-between;
		}
		@media (max-width: 1200px) {
			display: block;
		}
	}
	@media (max-width: 1200px) {
		margin-left: auto;
	}
	.login-box {
		position: relative;
		display: flex;
		> .btn {
			@media (min-width: 319px) and (max-width: 1024px) {
				display: none;
			}
		}
		.mobile-menu-btn {
			outline: none;
			border: none;
			background: none;
			padding: 0;
			margin: 0;
			cursor: pointer;
			display: none;
			@media (max-width: 1024px) {
				display: block;
			}
		}
	}
	.locale {
		position: relative;
		font-weight: 600;
		color: #3f5cdc;
		padding: 5px 40px;
		border-top: 1px solid #ebebeb;
		transition: background 0.3s ease;
		text-transform: uppercase;
		&:first-child {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		&__current {
			position: relative;
			font-weight: 600;
			color: #3f5cdc;
			padding: 5px 40px;
			border-top: 1px solid #ebebeb;
			transition: background 0.3s ease;
			text-transform: uppercase;
			opacity: 1;
			width: 30px;
			height: 30px;
			padding: 0;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid transparent;
			border-radius: 6px !important;
			transition: border 0.3s ease;
			&:hover {
				cursor: pointer;
				border: 1px solid #fff;
			}
			&:active {
				transform: scale(0.9);
			}
		}
		&:last-child {
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}
		&:not(.locale--active):not(.locale--hidden) {
			&:hover {
				cursor: pointer;
				background: #eee;
			}
		}
		&--active {
			opacity: 1;
			width: 30px;
			height: 30px;
			padding: 0;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid transparent;
			border-radius: 6px !important;
			transition: border 0.3s ease;
		}
		&--hidden {
			color: #ccc;
		}
		&__wrapper {
			position: relative;
			margin-right: 38px;
			&:focus {
				.locale__box {
					display: block !important;
				}
				.locale__triangle {
					display: block !important;
				}
			}
		}
		&__triangle {
			width: 20px;
			height: 20px;
			background: #fff;
			position: absolute;
			z-index: 0;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
		}
		&__box {
			border-radius: 10px;
			position: absolute;
			top: 150%;
			left: -120%;
			background: #fff;
			box-shadow: 0px 0px 8px 0px #0000002b;
			max-height: 130px;
			overflow: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
			&--active {
				display: block;
				overflow-y: auto;
				max-height: 135px;
				-ms-overflow-style: none;
				scrollbar-width: none;
				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
			&--hidden {
				display: none;
			}
			.locale {
				&:first-child {
					border-top: none;
				}
			}
		}
	}
	.noselect {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}

.slide-fade-top-enter-active,
.slide-fade-top-leave-active,
.slide-fade-enter-active,
.slide-fade-leave-active {
	transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(20px);
	opacity: 0;
}

.slide-fade-top-enter-from,
.slide-fade-top-leave-to {
	opacity: 0;
	@media (min-width: 319px) and (max-width: 769px) {
		transform: translateX(20px);
	}
}
</style>
