<template>
	<div class="card">
		<div class="card--icon">
			<component :is="icon" />
		</div>
		<div class="card--title">
			<h4>{{ title }}</h4>
		</div>
		<div class="card--content">
			<p>
				<slot />
			</p>
		</div>
	</div>
</template>
<script>
import Dns from '../../features-icons/Dns';
import TimeZone from '../../features-icons/TimeZone';
import Vpn from '../../features-icons/Vpn';
import VpnTor from '../../features-icons/VpnTor';
import Unlim from '../../features-icons/Unlim';
import Qos from '../../features-icons/Qos';
import NoLogs from '../../features-icons/NoLogs';
import Guard from '../../features-icons/Guard';
import IPSec from '../../features-icons/IPSec';
import SSTP from '../../features-icons/SSTP';
import L2TP from '../../features-icons/L2TP';
import OpenVpn from '../../features-icons/OpenVpn';
import Windows from '../../features-icons/Windows';
import MacOS from '../../features-icons/MacOS';
import IOS from '../../features-icons/IOS';
import Android from '../../features-icons/Android';
import Mikrot from '../../features-icons/Mikrot';
import Asus from '../../features-icons/Asus';
import TPLink from '../../features-icons/TPLink';
import Cisco from '../../features-icons/Cisco';
import DLink from '../../features-icons/DLink';
import OpenWRT from '../../features-icons/OpenWRT';
import Router from '../../features-icons/Router';

import Billing from '../../features-icons/Billing';
import PrivateIP from '../../features-icons/PrivateIP';
import PrivateServer from '../../features-icons/PrivateServer';
import WhiteLabel from '../../features-icons/WhiteLabel';

export default {
	name: 'Card',
	props: ['title', 'icon'],
	components: {
		Dns,
		TimeZone,
		Vpn,
		VpnTor,
		Unlim,
		Qos,
		NoLogs,
		Guard,
		IPSec,
		SSTP,
		L2TP,
		OpenVpn,
		Windows,
		MacOS,
		IOS,
		Android,
		Mikrot,
		Asus,
		TPLink,
		Cisco,
		DLink,
		OpenWRT,
		Router,
		Billing,
		PrivateIP,
		PrivateServer,
		WhiteLabel,
	},
};
</script>
<style scoped lang="scss">
.card {
	width: 262px;
	height: 340px;
	border-radius: 8px;
	border: 2px solid transparent;
	transition: all 0.2s ease;
	background-color: #ffffff;
	cursor: default;
	&:hover {
		box-shadow: 5px 8px 30px rgba(197, 197, 197, 0.5);
		border-color: #415bf5;
	}
	&--icon {
		padding-top: 53px;
		text-align: center;
		margin-bottom: 23px;
		height: 120px;
	}
	&--title {
		margin-bottom: 12px;
		padding: 0 30px;
		> * {
			font-size: 22px;
			line-height: 26.4px;
			text-align: center;
			font-weight: 500;
			color: #000000;
			margin: 0;
		}
	}
	&--content {
		text-align: center;
		> * {
			margin: 0;
			font-size: 16px;
			line-height: 24px;
			color: #6b6b6b;
			padding: 0 36px;
		}
	}
}
</style>
