import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{ path: '/:pathMatch(.*)*', redirect: { name: 'Home' } },
	{
		path: '/',
		name: 'Home',
		component: () =>
			import(/* webpackChunkName: "home" */ '../components/pages/Home'),
	},
	{
		path: '/user-agreement',
		name: 'Useragreement',
		component: () =>
			import(
				/* webpackChunkName: "useragreement" */ '../components/pages/Useragreement'
			),
	},
	{
		path: '/privacy-policy',
		name: 'Privacypolicy',
		component: () =>
			import(
				/* webpackChunkName: "privacypolicy" */ '../components/pages/Privacypolicy'
			),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return { el: to.hash };
		}
		return { x: 0, y: 0 };
	},
});

export default router;
