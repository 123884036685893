<template>
	<svg
		width="76"
		height="72"
		viewBox="0 0 76 72"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M62.8582 46.5365C70.1047 46.5365 76 40.6412 76 33.3947C76 27.8818 72.6193 23.0299 67.5155 21.1032C67.0608 18.0962 65.6495 15.2895 63.4958 13.1358C60.4066 10.0467 56.0604 8.54838 51.7372 9.04802C48.3425 3.52926 42.3051 0.132568 35.7982 0.132568C29.109 0.132568 22.9499 3.69759 19.6062 9.46929C13.9839 10.9825 9.92245 14.9337 9.348 20.8132C3.81039 22.4862 0 27.5928 0 33.3947C0 40.6412 5.89534 46.5365 13.1418 46.5365H34.3036V58.0594C33.5221 58.3492 32.9625 59.1001 32.9625 59.9812V61.7923H29.4377C28.5566 61.7923 27.8056 62.3519 27.5157 63.1335H6.9068C5.84027 63.1335 4.97266 64.0011 4.97266 65.0675V68.5921C4.97266 69.6586 5.84027 70.5261 6.9068 70.5261H27.5157C27.8055 71.3076 28.5564 71.8672 29.4377 71.8672H46.4133C47.2944 71.8672 48.0454 71.3076 48.3353 70.5261H69.0932C70.1597 70.5261 71.0273 69.6585 71.0273 68.5921V65.0675C71.0273 64.0009 70.1597 63.1335 69.0932 63.1335H48.3353C48.0455 62.3519 47.2946 61.7923 46.4133 61.7923H43.0375V59.9812C43.0375 59.1001 42.4779 58.3492 41.6964 58.0594V46.5365H62.8582ZM2.22656 33.3947C2.22656 28.1139 5.93379 23.8676 10.6553 22.7675C11.1417 22.6541 11.4932 22.2306 11.5149 21.7316C11.6207 19.2908 12.7183 13.4091 20.6034 11.513C20.9095 11.4394 21.17 11.2397 21.3207 10.9633C24.2131 5.65623 29.7605 2.35928 35.7982 2.35928C41.757 2.35928 47.2692 5.59344 50.1842 10.7994C50.4144 11.2108 50.8787 11.4324 51.3432 11.353C55.2045 10.693 59.159 11.9479 61.9216 14.7105C63.8823 16.6712 65.1139 19.2736 65.3895 22.0384C65.4336 22.4809 65.7369 22.8546 66.1608 22.9891C70.7141 24.433 73.7734 28.6148 73.7734 33.3947C73.7734 39.4134 68.8769 44.3099 62.8582 44.3099H13.1418C7.12307 44.3099 2.22656 39.4134 2.22656 33.3947ZM7.19922 65.3602H27.3864V68.2998H7.19922V65.3602ZM68.8008 68.2998H48.4645V65.3602H68.8008V68.2998ZM46.238 64.019V69.641H29.613V64.019H34.0758C34.6906 64.019 35.189 63.5207 35.189 62.9058V60.1567H40.811V62.9058C40.811 63.5207 41.3094 64.019 41.9242 64.019H46.238ZM39.4698 57.9301H36.5302V46.5365H39.4698V57.9301Z"
			fill="#415BF5"
		/>
		<path
			d="M42.2907 20.8166C44.5915 18.8168 46.9554 17.803 49.3164 17.803C54.9874 17.803 59.6008 22.4166 59.6008 28.0873C59.6008 33.7581 54.9872 38.3717 49.3164 38.3717C44.4918 38.3717 40.636 33.9962 38.7539 31.3651C37.3571 29.2208 32.8618 20.0266 26.6836 20.0266C22.2389 20.0266 18.6228 23.6425 18.6228 28.0873C18.6228 32.5318 22.2388 36.1479 26.6836 36.1479C28.2875 36.1479 30.1961 35.0603 32.5184 32.8229C32.9612 32.3963 32.9742 31.6915 32.5476 31.2487C32.121 30.8063 31.4164 30.7932 30.9736 31.2195C28.4424 33.6583 27.1302 33.9215 26.6837 33.9215C23.4668 33.9215 20.8495 31.3044 20.8495 28.0875C20.8495 24.8705 23.4666 22.2533 26.6837 22.2533C31.6263 22.2533 35.8934 31.19 36.8766 32.563C36.8814 32.5709 36.8873 32.5776 36.8922 32.5851C36.9014 32.5994 36.9097 32.6141 36.9197 32.628C39.9074 36.8177 44.1915 40.5982 49.3164 40.5982C56.2151 40.5982 61.8274 34.9858 61.8274 28.0873C61.8274 21.1888 56.2149 15.5764 49.3164 15.5764C46.4032 15.5764 43.548 16.774 40.8301 19.1358C40.3661 19.5391 40.3168 20.2423 40.7201 20.7064C41.1234 21.1704 41.8266 21.2196 42.2907 20.8166Z"
			fill="#31C6B4"
		/>
		<path
			d="M33.4477 34.9956C30.9454 37.2358 28.6697 38.3717 26.6837 38.3717C21.0128 38.3717 16.3994 33.7581 16.3994 28.0873C16.3994 22.4166 21.013 17.803 26.6837 17.803C31.4475 17.803 35.2313 22.0164 37.0763 24.5518C38.7631 27.1492 43.2156 36.1479 49.3164 36.1479C53.7611 36.1479 57.3772 32.532 57.3772 28.0873C57.3772 23.6427 53.7612 20.0266 49.3164 20.0266C47.3376 20.0266 45.3005 21.0043 43.2617 22.9327C42.815 23.3553 42.7954 24.0599 43.2179 24.5066C43.6403 24.9531 44.3448 24.973 44.7918 24.5505C45.8997 23.5024 47.5842 22.2531 49.3164 22.2531C52.5334 22.2531 55.1506 24.8704 55.1506 28.0873C55.1506 31.3043 52.5335 33.9214 49.3164 33.9214C44.4576 33.9214 40.2923 25.1952 38.9012 23.2751C34.2852 16.9121 29.7095 15.5764 26.6836 15.5764C19.7849 15.5764 14.1727 21.1888 14.1727 28.0873C14.1727 34.9858 19.7851 40.5982 26.6836 40.5982C29.2342 40.5982 32.0097 39.2715 34.9327 36.6545C35.3908 36.2444 35.4297 35.5407 35.0195 35.0824C34.6095 34.6244 33.9057 34.5858 33.4477 34.9956Z"
			fill="#31C6B4"
		/>
	</svg>
</template>
<script>
export default {
	name: 'Unlim',
};
</script>
<style scoped lang="scss"></style>
