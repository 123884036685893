<template>
	<svg
		:width="size"
		:height="size"
		viewBox="0 0 32 35"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		v-bind="$attrs"
	>
		<path
			d="M31.943 6.23886C31.943 5.73975 31.5686 5.24064 31.0695 5.11586L16.3458 0C16.0963 0 15.8467 0 15.5972 0L0.748663 4.99109C0.623886 5.11586 0.374332 5.24064 0.249554 5.36542L10.2317 8.60963C10.8556 8.85918 11.2299 9.48307 10.9804 10.2317C10.7308 10.9804 9.98218 11.2299 9.35829 10.9804L0 7.98574C0 8.85918 -1.20856e-07 9.73262 0.124777 10.6061L10.2317 13.975C10.8556 14.2246 11.2299 14.8485 10.9804 15.5971C10.6061 16.5954 9.73262 16.4706 9.35829 16.3458L0.374332 13.3512C0.499109 14.3494 0.748663 15.2228 0.998218 16.221L10.2317 19.3405C10.8556 19.59 11.2299 20.2139 10.9804 20.9626C10.7308 21.836 9.73262 21.836 9.35829 21.7112L1.87166 19.2157C4.24242 25.4545 8.60963 30.5704 15.2228 34.8128C15.5972 35.0624 16.0963 35.0624 16.5954 34.8128C27.3262 27.8253 32.4421 18.467 31.943 6.23886Z"
			fill="#ADADAD"
		/>
	</svg>
</template>
<script>
export default {
	name: 'Secure',
	inheritAttrs: false,
	props: {
		size: {
			type: String,
			default: '30',
		},
	},
};
</script>
