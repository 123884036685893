<template>
	<svg
		width="47"
		height="47"
		viewBox="0 0 47 47"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M31.7617 31.7617H15.2383C13.7173 31.7617 12.4844 32.9946 12.4844 34.5156V42.7773C12.4844 44.2981 13.7173 45.5312 15.2383 45.5312H31.7617C33.2827 45.5312 34.5156 44.2981 34.5156 42.7773V34.5156C34.5156 32.9946 33.2827 31.7617 31.7617 31.7617ZM24.877 40.0234C24.877 40.7845 24.2611 41.4004 23.5 41.4004C22.7389 41.4004 22.123 40.7845 22.123 40.0234V37.2695C22.123 36.5084 22.7389 35.8926 23.5 35.8926C24.2611 35.8926 24.877 36.5084 24.877 37.2695V40.0234Z"
			fill="#3C53DC"
		/>
		<path
			d="M23.5 20.7461C18.9371 20.7461 15.2383 24.445 15.2383 29.0078H20.7461C20.7461 27.4868 21.979 26.2539 23.5 26.2539C25.021 26.2539 26.2539 27.4868 26.2539 29.0078H31.7617C31.7617 24.445 28.0629 20.7461 23.5 20.7461Z"
			fill="#3C53DC"
		/>
		<path
			d="M38.6062 12.865C38.0978 6.49518 32.7514 1.46875 26.2539 1.46875C20.8254 1.46875 16.1244 4.97833 14.492 10.0276C13.8452 9.83135 13.1702 9.73047 12.4844 9.73047C8.8416 9.73047 5.84976 12.5744 5.61439 16.1594C2.27418 17.7367 0 21.1239 0 24.877C0 30.1912 4.41626 34.5156 9.73047 34.5156C9.73047 32.4856 10.8461 30.729 12.4844 29.7738V29.0078C12.4844 22.9339 17.4261 17.9922 23.5 17.9922C29.5739 17.9922 34.5156 22.9339 34.5156 29.0078V29.7738C36.1158 30.7068 37.2058 32.4068 37.2556 34.3777C42.6813 33.6975 47 29.1071 47 23.5C47 18.4709 43.4134 14.0899 38.6062 12.865Z"
			fill="#3C53DC"
		/>
	</svg>
</template>
