<template>
	<div :style="{ height: `${size}px` }" />
</template>
<script>
export default {
	name: 'Divider',
	props: {
		size: {
			type: [String, Number],
			default: 20,
		},
	},
};
</script>
