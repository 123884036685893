import { Strings } from './strings';
import { useLocalizationStore } from '../store/use';

export class Helper {
	static registerComponents(VueApp, components) {
		if (typeof VueApp.component === 'function') {
			Object.entries(components.default).forEach(
				([componentName, Component]) => {
					VueApp.component(componentName, Component);
				}
			);
		}
	}
	static strings(stringKey) {
		const { locale } = useLocalizationStore();
		return Strings.getString(locale, stringKey);
	}
}
