<template>
	<svg
		width="25"
		height="25"
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		v-bind="$attrs"
		class="loader-icon"
	>
		<path
			d="M17.7142 6.53239C18.0734 6.83411 18.5297 7 18.9979 7C19.5919 7 20.151 6.73962 20.5328 6.28561C21.242 5.44104 21.1312 4.17712 20.2861 3.46787C19.9264 3.16614 19.4701 3 19.0015 3C18.4077 3 17.8486 3.26038 17.4673 3.71439C16.7579 4.55922 16.8689 5.82288 17.7142 6.53239Z"
			fill="white"
		/>
		<path
			d="M23.0035 9C22.8866 9 22.7684 9.01041 22.653 9.03074C21.5673 9.22221 20.8391 10.2618 21.0306 11.348C21.1991 12.3053 22.0258 13 22.996 13C23.1127 13 23.2313 12.9896 23.3473 12.9695C24.433 12.7778 25.1609 11.7384 24.9694 10.6528C24.8005 9.69519 23.9735 9 23.0035 9Z"
			fill="white"
		/>
		<path
			d="M22.751 16.2683C22.371 16.0925 21.94 16 21.5049 16C20.6126 16 19.7815 16.3834 19.3359 17.0006C19.0025 17.4627 18.9132 18.0011 19.0863 18.5171C19.2595 19.0331 19.6736 19.4646 20.2509 19.7313C20.6312 19.9073 21.0622 20 21.4978 20C22.3896 20 23.2207 19.6168 23.666 18.9999C24.3546 18.0449 23.9442 16.8195 22.751 16.2683Z"
			fill="white"
		/>
		<path
			d="M16.5 20C16.2096 20 15.9219 20.051 15.645 20.1514C15.018 20.3797 14.5177 20.8387 14.2354 21.4437C13.9534 22.0486 13.9238 22.7272 14.1518 23.3544C14.5101 24.3386 15.4542 25 16.5008 25C16.7913 25 17.079 24.949 17.3551 24.8483C17.9815 24.62 18.4815 24.1613 18.7641 23.5553C19.0464 22.95 19.0762 22.2715 18.8488 21.6453C18.491 20.6614 17.5467 20 16.5 20Z"
			fill="white"
		/>
		<path
			d="M10.3548 20.1518C10.0776 20.0511 9.79001 20 9.50041 20C8.45401 20 7.51034 20.6613 7.15233 21.6455C6.92364 22.273 6.95332 22.9516 7.23544 23.5563C7.51731 24.1614 8.01779 24.6201 8.64527 24.8485C8.92197 24.9494 9.20977 25 9.50015 25C10.5471 25 11.4905 24.3387 11.8482 23.3553C12.0764 22.7291 12.0465 22.0505 11.7641 21.4452C11.4814 20.8391 10.9812 20.38 10.3548 20.1518Z"
			fill="white"
		/>
		<path
			d="M6.6659 17.2499C6.22033 16.4788 5.39001 16 4.49839 16C4.06242 16 3.63138 16.1162 3.25105 16.335C2.67256 16.669 2.259 17.2086 2.08643 17.8528C1.91338 18.4977 2.002 19.1714 2.33605 19.7496C2.78161 20.521 3.61218 21 4.5038 21C4.93952 21 5.37056 20.8838 5.75064 20.6647C6.94407 19.9758 7.35468 18.4438 6.6659 17.2499Z"
			fill="white"
		/>
		<path
			d="M3.00423 14C4.45968 14 5.7004 13.131 5.95379 11.9338C6.09262 11.276 5.91626 10.6128 5.4566 10.0665C4.99751 9.5195 4.31013 9.15451 3.52061 9.03833C3.34566 9.01293 3.16874 9 2.99577 9C1.54032 9 0.2996 9.86873 0.0462086 11.066C-0.0926208 11.7233 0.0837376 12.3865 0.543398 12.9335C1.00278 13.4803 1.69043 13.8455 2.47939 13.9614C2.65349 13.9871 2.83013 14 3.00423 14Z"
			fill="white"
		/>
		<path
			d="M6.50225 8C7.08753 8 7.65773 7.79216 8.1079 7.415C8.61865 6.98649 8.9319 6.3837 8.99022 5.71763C9.04855 5.05157 8.84452 4.40351 8.41575 3.89282C7.93968 3.32538 7.24089 3 6.49909 3C5.91291 3 5.34271 3.20761 4.89322 3.58478C4.38179 4.01396 4.06787 4.61675 4.00977 5.28192C3.95144 5.94663 4.1557 6.59491 4.58515 7.10673C5.06189 7.6744 5.76045 8 6.50225 8Z"
			fill="white"
		/>
		<path
			d="M13 6C14.6569 6 16 4.65685 16 3C16 1.34315 14.6569 0 13 0C11.3431 0 10 1.34315 10 3C10 4.65685 11.3431 6 13 6Z"
			fill="white"
		/>
	</svg>
</template>
<style lang="scss">
@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loader-icon {
	animation: rotate 2s linear infinite;
}
</style>
