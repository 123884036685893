<template>
	<transition name="slide-fade-top">
		<div v-if="open" class="app-box">
			<div class="app-box__wrapper">
				<a href="#" class="app-box__button" @click="appIos">
					<IconApple :color="'currentColor'" class="modifier-svg" />
					<span class="app-box__title">{{ translate.button9 }}</span>
				</a>
				<a href="#" class="app-box__button" @click="appAndroid">
					<IconAndroid :color="'currentColor'" class="modifier-svg" />
					<span class="app-box__title">{{ translate.button10 }}</span>
				</a>
        <a href="#" class="app-box__button" @click="appHuawei">
					<IconAppgallery :color="'currentColor'" class="modifier-svg" />
					<span class="app-box__title">{{ translate.button12 }}</span>
        </a>
			</div>
		</div>
	</transition>
</template>

<script>
import IconAndroid from '@/components/features-icons/Android';
import IconApple from '@/components/features-icons/Apple';
import IconAppgallery from '@/components/features-icons/Appgallery';

export default {
	components: {
		IconAndroid,
		IconApple,
		IconAppgallery,
	},
	inject: ['translate'],
	props: {
		open: Boolean,
	},
	setup() {
		const appAndroid = () => {
			window.open(
				'https://play.google.com/store/apps/details?id=ru.dubki.vpn',
				'_blank'
			);
		};
		const appIos = () => {
			window.open(
				'https://apps.apple.com/ru/app/dubkivpn-fast-secure-vpn/id1628019423',
				'_blank'
			);
		};
    const appHuawei = () => {
			window.open(
				'https://appgallery.huawei.com/#/app/C107900651',
				'_blank'
			);
    };
		return {
			appAndroid,
			appIos,
      appHuawei,
		};
	},
};
</script>

<style scoped lang="scss">
.app-box {
	width: 270px;
	height: 196px;
	border-radius: 5px;
	background-color: #fff;
	position: absolute;
	left: 0;
	top: 70px;
	filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
	@media (max-width: 320px) {
		width: 290px;
	}
	@media (min-width: 321px) and (max-width: 375px) {
		width: 345px;
	}
	@media (min-width: 376px) and (max-width: 414px) {
		width: 386px;
	}
	&:before {
		content: '';
		display: block;
		position: inherit;
		width: 25px;
		height: 25px;
		background-color: #fff;
		border-radius: 3px;
		left: 83px;
		transform: translateY(-40%) rotate(-45deg);
	}
	&__wrapper {
		background: #fff;
		border-radius: 5px;
		position: relative;
		height: 100%;
		overflow-y: auto;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		&::-webkit-scrollbar {
			width: 0;
		}
	}
	&__title {
		display: flex;
	}
	&__button {
		text-decoration: none;
		display: flex;
		align-items: center;
		border: 1px solid #d5dbfd;
		border-radius: 5px;
		width: calc(100% - 20px);
		margin: 10px;
		color: #4fc6b4;
		font-weight: bold;
		transition: all 0.45s ease;
		svg {
			height: 40px;
			margin: 5px 0;
		}
		&:hover {
			cursor: pointer;
			color: #fff;
			background: #4fc6b4;
		}
	}
}

.slide-fade-top-enter-active,
.slide-fade-top-leave-active {
	transition: all 0.3s ease-out;
}

.slide-fade-top-enter-from,
.slide-fade-top-leave-to {
	opacity: 0;
}
@media (max-width: 500px) {
	.app-box {
		top: -220px;
		&:before {
			bottom: -18px;
		}
	}
}
</style>
