/** Auth store */
import { useStore } from 'vuex';
import { AuthService } from '../../services/auth';
import { http } from '../../../http';
import { computed } from 'vue';
import { alertStore } from '../Alert';
import Cookies from 'js-cookie';
import { ratesStore } from '../RatesStore';
import { newAccountLogin, trialBlocking } from '../../../helpers/notification';

const service = new AuthService();

const AUTH_TOKEN = 'dvpn_auth_token';

const state = {
	awaitingForConfirmed: false,
	loading: false,
	type: 'email', // второй тип phone (в разработке)
	email: '',
	phone: '', // в разработке
	token: Cookies.get(AUTH_TOKEN) ?? null,
	sessionId: null,
	code: '',
  isAuthCheck: false,
	domain:
		process.env.VUE_APP_MODE === '0'
			? 'domain.com'
			: process.env.VUE_APP_MODE === '1'
			? 'dl-net.ru '
			: 'dubki-vpn.ru',
};

const getters = {
	isAuth: (state) => state.token !== null,
	isAuthCheck: (state) => state.isAuthCheck,
	token: (state) => state.token,
	domain: (state) => state.domain,
};

const actions = {
	getValue({ state }) {
		if (state.type === 'email') {
			return state.email;
		}

		return state.phone;
	},
	async authWithSessionId({ state, dispatch }) {
		if (state.loading) return;

		try {
			state.loading = true;
			const {
				data: { data },
			} = await service.authWithSessionId(state.sessionId, state.code);

			dispatch('setToken', data.auth_token);
			dispatch('reset');
      state.isAuth = true;
		} catch (error) {
			const errors = error.response.data;

			let errMessage = 'Непредвиденная ошибка сервера';

			if (errors.data.error_details === 'Invalid validation code.') {
				errMessage = 'Неверный код';
			}

			alertStore.addMessage('Ошибка!', errMessage);
		} finally {
			state.loading = false;
		}
	},
	async auth({ state, dispatch }) {
		if (state.loading) return;

		try {
			state.loading = true;
			state.loading = true;
			const {
				data: { data },
			} = await service.auth(state.type, state.email);

			const { auth_token, session_id, is_new_user, trial } = data;

			if (trial && is_new_user && !ratesStore.state.currentRate) {
				newAccountLogin();
			} else if (!trial && !Cookies.get("is_view_trial:"+state.email)){
        Cookies.set('is_view_trial:'+state.email, 'no', { expires: 7});
        trialBlocking();
      }

			if (is_new_user) {
				window.ym(45609672, 'reachGoal', 'landing_is_new_user');
			}

			if (auth_token) {
				dispatch('setToken', auth_token);
				dispatch('reset');
        state.isAuth = true;
				if (ratesStore.state.currentRate !== null) {
					ratesStore.makeSubscribe();

					ratesStore.reset();
				}
			}

			if (session_id) {
				dispatch('setSessionId', session_id);
			}
		} catch (error) {
			alertStore.addMessage('Ошибка!', 'Непредвиденная ошибка сервера');
		} finally {
			state.loading = false;
		}
	},
  async setLocale(locale) {
    return await service.language(locale);
  },
	setSessionId({ state }, id) {
		state.sessionId = id;
		state.awaitingForConfirmed = true;
	},
	setToken({ state }, token) {
		http.defaults.headers.Authorization = `Bearer ${token}`;
		state.token = token;

    if (state.email) {
		  Cookies.set('dvpn_auth_email', state.email, { path: '/', domain: state.domain });
    }
		Cookies.set('dvpn_auth_token', token, { path: '/', domain: state.domain });
	},
  setCurrentToken({ state }, token) {
    state.token = token;
  },
  setIsAuth({ state }, value) {
    state.isAuthCheck = value;
  },
	logout({ state }) {
		state.token = null;
		state.email = '';
		state.phone = '';
		Cookies.remove('dvpn_auth_token', { path: '/', domain: state.domain });
		Cookies.remove('dvpn_auth_email', { path: '/', domain: state.domain });
		delete http.defaults.headers.Authorization;

		ratesStore.reset();
    state.isAuthCheck = false;
	},
	reset({ state }) {
		state.code = '';
		state.awaitingForConfirmed = false;
		state.sessionId = null;
	},
};

const mutations = {};

export const useAuthStore = () => {
	const { state, dispatch, getters } = useStore();

	return {
		state: state.AuthStore,
		isAuth: computed(() => getters['AuthStore/isAuth']),
		logout: () => dispatch('AuthStore/logout'),
		reset: () => dispatch('AuthStore/reset'),
		auth: () => {
			if (state.AuthStore.awaitingForConfirmed) {
				dispatch('AuthStore/authWithSessionId');

				return;
			}

			dispatch('AuthStore/auth');
		},
	};
};

export const AuthStore = {
	namespaced: true,
	// state: authState,
	state,
	getters,
	actions,
	mutations,
};
