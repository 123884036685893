import { reactive } from 'vue';

const state = reactive({
  show: false,
  title: null,
  content: null,
  icon: null,
  buttonText: null,
  resolve: null,
});

const actions = {
  show(data) {
    if (!data.title || !data.content || !data.icon || !data.buttonText) {
      return;
    }
    state.title = data.title;
    state.content = data.content;
    state.icon = data.icon;
    state.buttonText = data.buttonText;
    state.show =  true;
    return new Promise((resolve, reject) => {
     state.resolve = resolve;
    });
  },
  confirm() {
    state.show = false;
    state.resolve({
      isConfirmed: true,
      isClose: true,
    });
  },
  close() {
    state.show = false;
    state.resolve({
      isConfirmed: false,
      isClose: true,
    });
  },
};

export const notificationStore = {
  state,
  ...actions,
};
