<template>
	<div class="header-menu">
    <router-link to="/#wiv">{{ translate.nav[0] }}</router-link>
    <router-link to="/#capabilities">{{ translate.nav[1] }}</router-link>
    <router-link to="/#pricing">{{ translate.nav[2] }}</router-link>
	</div>
</template>
<script>

export default {
	name: 'HeaderMenu',
  inject: ['translate'],
  data() {
    return {
    }
  }
};
</script>
<style scoped lang="scss">
.header-menu {
	flex: 1 0 auto;
	display: flex;
	justify-content: center;
	@media (max-width: 1024px) {
		display: none;
	}
	a {
		&:not(:last-child) {
			margin-right: 54px;
		}
		font-size: 16px;
		font-weight: 400;
		color: #fff;
		text-decoration: none;
	}
}
</style>
