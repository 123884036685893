<template>
	<svg
		width="64"
		height="64"
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.9573 0C4.80909 0 0 4.80825 0 17.9538V46.0462C0 59.1917 4.80909 64 17.9573 64H46.0341C59.182 64 64 59.1917 64 46.0462V17.9538C64 4.80825 59.1909 0 46.0427 0H17.9573ZM27.9168 37.1187H30.4861L29.1971 34.1208L27.9168 37.1187ZM27.2841 38.6294L26.5217 40.3737H24.7862L28.4769 32.0005H29.9769L33.6527 40.3737H31.8708L31.1183 38.6294H27.2841ZM55.2117 40.3675H56.8891V32H55.2117V40.3675ZM48.5355 36.773H51.6274V35.2473H48.5355V33.5324H53.024V32.0062H46.8586V40.3732H53.1855V38.847H48.5355V36.773ZM41.8705 37.7617L39.9688 32H38.581L36.6793 37.7617L34.8286 32.0046H33.019L35.9397 40.3783H37.3465L39.2513 34.878L41.1561 40.3783H42.5752L45.4882 32.0046H43.7254L41.8705 37.7617ZM22.2256 36.7961C22.2256 38.1582 21.5492 38.8861 20.3208 38.8861C19.0858 38.8861 18.4057 38.1372 18.4057 36.738V32.0057H16.7057V36.7961C16.7057 39.1526 18.0153 40.5039 20.2976 40.5039C22.6026 40.5039 23.9241 39.1268 23.9241 36.7262V32.0005H22.2256V36.7961ZM12.6497 32.0005H14.3488V40.3789H12.6497V36.9762H8.81138V40.3789H7.11133V32.0005H8.81138V35.38H12.6497V32.0005ZM21.334 10.667C21.334 16.5487 26.1185 21.3337 32.0007 21.3337C37.8823 21.3337 42.6673 16.5487 42.6673 10.667H41.1606C41.1606 15.7174 37.0511 19.8265 32.0007 19.8265C26.9502 19.8265 22.8407 15.7174 22.8407 10.667H21.334Z"
			:fill="color ? color : '#4FC6B4'"
		/>
	</svg>
</template>

<script>
export default {
	name: 'Appgallery',
	props: {
		color: String,
	},
};
</script>

<style></style>
