<template>
	<svg
		width="75"
		height="75"
		viewBox="0 0 75 75"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M74.1993 11.4383C74.7383 11.9363 75.0298 12.6481 74.9976 13.382C74.6431 21.1569 71.2288 60.6598 38.5003 74.7449C37.8617 75.0188 37.1381 75.0188 36.4995 74.7449C3.76808 60.6598 0.355206 21.1569 0.0022004 13.382C-0.0285594 12.6467 0.264391 11.9363 0.804885 11.4383C1.34831 10.9344 2.08508 10.6956 2.82039 10.7835C23.1614 13.2399 33.0719 3.7776 35.625 0.818797C36.1084 0.29588 36.788 0 37.4999 0C38.2118 0 38.8914 0.29588 39.3748 0.818797C41.9322 3.7776 51.8457 13.2355 72.1823 10.7835C72.9176 10.6956 73.6559 10.9344 74.1993 11.4383ZM72.4972 13.2677V13.2648C50.9229 15.8647 40.2492 5.64663 37.4867 2.45639C34.7506 5.64663 24.0725 15.8618 2.51865 13.2648C2.84236 20.7834 6.12634 58.9519 37.4867 72.4511C68.8735 58.9519 72.1574 20.7834 72.4972 13.2677Z"
			fill="#415BF5"
		/>
		<path
			d="M37.4779 66.9491C37.4896 66.952 37.5014 66.952 37.5131 66.9491C59.3042 56.3194 65.3463 32.1363 67.0103 18.6664C66.5533 18.6898 66.0875 18.6898 65.6232 18.6898C51.6626 18.6898 42.6251 13.6481 37.5131 9.4194C32.3674 13.6496 23.3343 18.6898 9.37662 18.6898C8.91229 18.6898 8.44504 18.684 7.97485 18.6737C9.65053 32.1348 15.6941 56.3164 37.4779 66.9491ZM68.8735 16.9936C69.3597 17.5312 69.5868 18.2533 69.496 18.9725C67.772 32.9477 61.4662 58.0478 38.6146 69.1945C37.9115 69.5358 37.0912 69.5358 36.3881 69.1945C13.5394 58.0463 7.23368 32.9433 5.5082 18.9725C5.41885 18.2533 5.64736 17.5297 6.13365 16.9907C6.62288 16.4619 7.31425 16.1661 8.0349 16.1748C8.48458 16.1851 8.93133 16.1895 9.37662 16.1895C22.5916 16.1895 31.0916 11.4613 35.8931 7.49325C36.8261 6.72426 38.1751 6.72279 39.1082 7.49179C43.9097 11.4583 52.4082 16.188 65.6261 16.188C66.0714 16.188 66.5181 16.1836 66.9678 16.1734C67.6929 16.1558 68.3886 16.4561 68.8735 16.9936Z"
			fill="#415BF5"
		/>
		<path
			d="M56.5006 40.2626C56.5065 40.8485 56.1066 41.3597 55.5368 41.4944C54.967 41.6277 54.3811 41.348 54.1262 40.8221L49.0011 30.5586V40.2626C49.0011 40.9539 48.4415 41.5135 47.7516 41.5135C47.0603 41.5135 46.5007 40.9539 46.5007 40.2626V25.2635C46.4949 24.6791 46.8948 24.1679 47.4645 24.0331C48.0343 23.8998 48.6217 24.1781 48.8766 24.7054L54.0003 34.969V25.2635C54.0003 24.5736 54.5598 24.0141 55.2512 24.0141C55.9411 24.0141 56.5006 24.5736 56.5006 25.2635V40.2626Z"
			fill="#31C6B4"
		/>
		<path
			d="M39.0012 24.0139C41.7623 24.0139 44.0019 26.2521 44.0019 29.0131C44.0019 31.7742 41.7623 34.0138 39.0012 34.0138H36.5023V40.2624C36.5023 40.9538 35.9428 41.5133 35.2514 41.5133C34.5615 41.5133 34.002 40.9538 34.002 40.2624V26.5143C34.002 25.133 35.1211 24.0139 36.5023 24.0139H39.0012ZM41.5016 29.0131C41.5016 27.6333 40.3825 26.5143 39.0012 26.5143H36.5023V31.5135H39.0012C40.3825 31.5135 41.5016 30.3944 41.5016 29.0131Z"
			fill="#31C6B4"
		/>
		<path
			d="M31.3699 24.7037C31.5193 25.0011 31.5427 25.3438 31.4387 25.6588L26.438 40.6578C26.2696 41.1705 25.7906 41.5177 25.2516 41.5177C24.7111 41.5177 24.2321 41.1705 24.0637 40.6578L19.0645 25.6588C18.9224 25.234 19.019 24.7667 19.3164 24.4328C19.6123 24.0988 20.0649 23.9479 20.5028 24.0373C20.9408 24.1281 21.2982 24.4445 21.4388 24.8692L25.253 36.3104L29.0658 24.8692C29.1713 24.5543 29.3968 24.2936 29.6927 24.1457C29.9886 23.9977 30.3328 23.9728 30.6477 24.0783C30.9612 24.1823 31.2219 24.4079 31.3699 24.7037Z"
			fill="#31C6B4"
		/>
		<path
			d="M37.5096 52.8101L35.1336 49.7261L37.2576 46.8941H35.0856L33.9936 48.6701L32.8896 46.8941H30.7176L32.8416 49.7261L30.4656 52.8101H32.6496L33.9936 50.8421L35.3256 52.8101H37.5096ZM44.4025 52.8101V51.3101H40.5625L42.2785 49.7621C42.9385 49.1741 44.0185 48.3101 44.0185 46.8581C44.0185 45.4061 42.8785 44.4101 41.0065 44.4101C39.1345 44.4101 38.1145 45.5261 38.0065 45.7781L38.8225 47.0501C39.2785 46.4741 39.9985 45.9341 40.8265 45.9341C41.6425 45.9341 42.1585 46.3901 42.1585 47.0981C42.1585 47.8061 41.6905 48.3461 40.9105 49.0541L38.1025 51.5861L38.3305 52.8101H44.4025Z"
			fill="#31C6B4"
		/>
	</svg>
</template>
<script>
export default {
	name: 'Vpn',
};
</script>
<style scoped lang="scss"></style>
