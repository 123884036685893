<template>
	<svg
		:width="size"
		:height="size"
		viewBox="0 0 35 35"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M25.9288 15.5544C24.1834 15.4043 22.7473 14.1551 22.3196 12.5034H18.5254V22.4966H25.5518C25.9575 20.2538 26.0884 17.8976 25.9288 15.5544Z"
			fill="#ADADAD"
		/>
		<path
			d="M26.2821 9.43618C25.1562 9.43618 24.2402 10.3521 24.2402 11.4781C24.2402 12.604 25.1562 13.5199 26.2821 13.5199C27.4081 13.5199 28.324 12.604 28.324 11.4781C28.324 10.3521 27.408 9.43618 26.2821 9.43618Z"
			fill="#ADADAD"
		/>
		<path
			d="M8.61956 26.673C10.3227 26.673 11.7033 25.2924 11.7033 23.5893C11.7033 21.8862 10.3227 20.5055 8.61956 20.5055C6.91645 20.5055 5.5358 21.8862 5.5358 23.5893C5.5358 25.2924 6.91645 26.673 8.61956 26.673Z"
			fill="#ADADAD"
		/>
		<path
			d="M16.4746 0C14.1341 1.60836 12.2311 4.32018 10.9173 7.4602C10.5199 8.41005 10.1825 9.41288 9.90637 10.4526H16.4746V0Z"
			fill="#ADADAD"
		/>
		<path
			d="M9.02194 18.472C11.2978 18.6494 13.1614 20.316 13.636 22.4966H16.4746V12.5034H9.45021C9.1011 14.4341 8.95412 16.4525 9.02194 18.472Z"
			fill="#ADADAD"
		/>
		<path
			d="M16.4746 35V24.5474H13.6631C13.3772 26.0557 12.4299 27.332 11.1348 28.063C12.4151 30.9841 14.2047 33.4691 16.4746 35Z"
			fill="#ADADAD"
		/>
		<path
			d="M18.5254 35C20.9791 33.316 22.9445 30.4525 24.2627 27.0966C24.5844 26.2779 24.8624 25.4253 25.096 24.5474H18.5254V35Z"
			fill="#ADADAD"
		/>
		<path
			d="M26.1715 27.8465C25.1532 30.4389 23.7745 32.6421 22.096 34.3889C27.2222 32.9971 31.4129 29.3346 33.5217 24.5474H27.2109C26.9322 25.6875 26.5854 26.7926 26.1715 27.8465Z"
			fill="#ADADAD"
		/>
		<path
			d="M8.61957 28.7239C6.11584 28.7239 4.02623 26.9221 3.57601 24.5474H1.47834C3.57895 29.316 7.74532 32.9689 12.8445 34.3727C11.3563 32.8291 10.1283 30.9324 9.17232 28.6937C8.99062 28.7132 8.80633 28.7239 8.61957 28.7239Z"
			fill="#ADADAD"
		/>
		<path
			d="M27.9608 15.209C28.144 17.6597 28.0289 20.131 27.6324 22.4966H34.2749C34.7458 20.9132 35 19.2364 35 17.5C35 15.7636 34.7458 14.0868 34.2749 12.5034H30.2447C29.9318 13.7116 29.0791 14.7039 27.9608 15.209Z"
			fill="#ADADAD"
		/>
		<path
			d="M6.9786 18.7252C6.89575 16.6277 7.03056 14.5257 7.36969 12.5034H0.725088C0.25416 14.0868 0 15.7636 0 17.5C0 19.2364 0.25416 20.9132 0.725088 22.4966H3.60322C3.98815 20.7279 5.28698 19.2975 6.9786 18.7252Z"
			fill="#ADADAD"
		/>
		<path
			d="M26.2607 7.38585C26.2678 7.38579 26.2749 7.38531 26.2821 7.38531C28.1848 7.38531 29.7883 8.69049 30.2446 10.4526H33.5217C31.4127 5.66498 27.2215 2.00214 22.0945 0.610758C22.4886 1.02119 22.8672 1.45705 23.2288 1.91881C24.4587 3.48917 25.4762 5.32564 26.2607 7.38585Z"
			fill="#ADADAD"
		/>
		<path
			d="M18.5254 0V10.4526H22.3196C22.6004 9.36831 23.3162 8.4579 24.2682 7.91725C23.2561 5.33347 21.5037 2.03526 18.5254 0Z"
			fill="#ADADAD"
		/>
		<path
			d="M9.02542 6.66864C10.0217 4.28741 11.333 2.24797 12.906 0.610631C7.77889 2.00202 3.58736 5.66485 1.47827 10.4527H7.79044C8.11262 9.13334 8.52558 7.86336 9.02542 6.66864Z"
			fill="#ADADAD"
		/>
	</svg>
</template>
<script>
export default {
	name: 'Proto',
	inheritAttrs: false,
	props: {
		size: {
			type: String,
			default: '30',
		},
	},
};
</script>
