<template>
	<div
		:class="{
			'input-field-container': true,
			'full-width': fullWidth,
			'space-error': !!errorMessage,
		}"
		:style="{ width: `${width}px` }"
	>
		<div
			:class="{ 'input-field-control': true, invalid: !valid }"
			v-bind="$attrs"
		>
			<div class="input-field">
				<input
					type="text"
					:value="modelValue"
					@input="updateValue"
					v-bind="$attrs"
				/>
			</div>
			<div v-if="$slots.button" class="input-content">
				<slot name="button" />
			</div>
		</div>
		<div v-if="!!errorMessage" class="error-text">
			<span>{{ errorMessage }}</span>
		</div>
	</div>
</template>
<script>
export default {
	name: 'InputField',
	props: {
		width: [String, Number],
		fullWidth: Boolean,
		modelValue: {
			type: String,
			default: '',
		},
		valid: {
			type: Boolean,
			default: true,
		},
		errorMessage: String,
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const updateValue = (event) => {
			emit('update:modelValue', event.target.value);
		};
		return { updateValue };
	},
};
</script>
<style scoped lang="scss">
.input-field-container {
	position: relative;
	z-index: 1;
	font-size: 14px;
	font-weight: 300;
	min-width: 245px;
	&.full-width {
		width: 100% !important;
	}
	&.space-error {
		margin-bottom: 35px;
	}
	.input-field-control {
		width: 100%;
		height: 50px;
		border: 1px solid #c5c5c5;
		border-radius: 6px;
		background-color: #fff;
		display: flex;
		overflow: hidden;
		&:focus-within {
			border-color: #415bf5;
		}
		&.invalid {
			border-color: #c63131;
			input {
				color: #c63131 !important;
			}
		}
		&[disabled='true'] {
			background-color: #f5f5f5;
			border-color: #f5f5f5;
		}
		.input-field {
			width: 100%;
			height: 100%;
			input {
				width: 100%;
				height: 100%;
				color: #6b6b6b;
				outline: none;
				border: none;
				padding-left: 26px;
				padding-right: 0;
				background: transparent !important;
				&::-webkit-input-placeholder {
					color: #c5c5c5;
				}
				&:disabled {
					color: #c5c5c5;
					cursor: not-allowed;
				}
				font-size: 16px;
			}
		}
		.input-content {
			flex: none;
			height: 100%;
			width: 66px;
			background: transparent !important;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.error-text {
		position: absolute;
		width: 100%;
		height: 85px;
		border-radius: 6px;
		background-color: #c63131;
		padding-top: 55px;
		padding-left: 26px;
		padding-right: 26px;
		top: 0;
		z-index: -1;
		span {
			color: #fff;
		}
	}
}
</style>
