<template>
	<router-view />
</template>

<style lang="scss">
html,
body {
	margin: 0;
	padding: 0;
	font-family: 'SF Pro Display', sans-serif;
	font-weight: 100;
	overflow: auto;
	scroll-behavior: smooth;
	background: #415bf5 !important;
}
#app {
	height: 100%;
}
* {
	box-sizing: border-box;
}
.text-center {
	text-align: center;
}
.d-flex {
	display: flex;
}
.direction-column {
	flex-direction: column;
}
.align-items-center {
	align-items: center;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-flex-end {
	justify-content: flex-end;
}
.text-upper {
	text-transform: uppercase;
}
.wrap-reverse {
	flex-wrap: wrap-reverse !important;
}
button {
	outline: none;
	border: none;
	background: none;
}
.relative {
	position: relative;
}
</style>
