import axios from 'axios';
import Cookies from 'js-cookie';
import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

const baseURL = `${process.env.VUE_APP_DVPN_API_URL}/${process.env.VUE_APP_DVPN_API_PREFIX}`;
const http = axios.create({ baseURL });
const accessToken = { value: Cookies.get('authToken') };
const fpPromise = FingerprintJS.load({ apiKey: 'ovmoT0F3ClceU251slzR' });

http.interceptors.request.use((config) => {
  if (!accessToken.value) {
    accessToken.value = Cookies.get('dvpn_auth_token');
  }
  if (accessToken.value) {
    config.headers.Authorization = `Bearer ${accessToken.value}`;
  }
  return fpPromise
  .then((fp) => fp.get())
  .then((result) => {
    config.headers['X-fp-id'] = result.visitorId;
    return config;
  })
  .catch(() => config);
});

export { http };
