<template>
	<svg
		width="120"
		height="28"
		viewBox="0 0 120 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.2802 9.33534L0.0388783 26.7405C-0.0644811 26.9003 0.0503627 27.1113 0.240675 27.1113H7.57099C7.65232 27.1113 7.72826 27.07 7.77255 27.0018L18.6009 10.3286C18.6986 10.1782 18.603 9.97752 18.4247 9.95877L11.5071 9.22682C11.4171 9.21721 11.3292 9.25916 11.2802 9.33534Z"
			fill="#415BF5"
		/>
		<path
			d="M13.2681 6.65535C13.0799 6.65535 12.9648 6.4491 13.0635 6.28879C13.7312 5.20621 15.6857 2.0534 16.2432 1.32941C16.8901 0.442771 17.7477 0.0647247 18.6486 0.0647247H29.1268C29.2595 0.0647247 29.3671 0.172301 29.3671 0.304958V4.66644C29.3671 4.92965 29.7301 5.00301 29.8285 4.75879C30.1131 4.05285 30.523 3.22879 31.0421 2.69254C32.0429 1.65379 33.3784 0.214723 36.4276 0.0220668C36.4339 0.0215981 36.4403 0.0213623 36.4466 0.0213623H58.6658C58.7985 0.0213623 58.9061 0.128943 58.9061 0.261599V6.41465C58.9061 6.5473 58.7985 6.65488 58.6658 6.65488H13.2681V6.65535Z"
			fill="#415BF5"
		/>
		<path
			d="M120 6.39867V0.240233C120 0.107577 119.893 0 119.76 0H95.969C95.9626 0 95.9563 0.000232201 95.95 0.000700951C92.507 0.218904 89.9148 2.32852 88.9035 6.06938C88.8981 6.08977 88.8955 6.10969 88.8955 6.13078V6.39375C88.8955 6.52641 89.0031 6.63398 89.1357 6.63398L119.76 6.63914C119.893 6.63891 120 6.53133 120 6.39867Z"
			fill="#415BF5"
		/>
		<path
			d="M66.556 6.63375H60.3188C60.1862 6.63375 60.0786 6.52617 60.0786 6.39351V0.272337C60.0786 0.139681 60.1862 0.0321045 60.3188 0.0321045H66.556C66.6887 0.0321045 66.7963 0.139681 66.7963 0.272337V6.39351C66.7965 6.52617 66.6889 6.63375 66.556 6.63375Z"
			fill="#415BF5"
		/>
		<path
			d="M88.6063 6.63375H82.3752C82.2426 6.63375 82.135 6.52617 82.135 6.39351V0.272337C82.135 0.139681 82.2426 0.0321045 82.3752 0.0321045H88.6063C88.739 0.0321045 88.8466 0.139681 88.8466 0.272337V6.39351C88.8466 6.52617 88.739 6.63375 88.6063 6.63375Z"
			fill="#415BF5"
		/>
		<path
			d="M88.6204 11.18L82.5018 10.8181C82.3638 10.8099 82.2473 10.9196 82.2473 11.0581V18.4622C82.2473 18.4622 82.2274 20.6684 80.0721 20.6684H68.7433C68.7344 20.6684 68.7269 20.6682 68.718 20.6672C68.5499 20.6492 66.8106 20.415 66.8106 18.4826V9.98722C66.8106 9.86113 66.7131 9.75659 66.5875 9.74745L60.4024 9.30542C60.2631 9.29558 60.145 9.40574 60.145 9.54519V21.0071C60.145 21.0237 60.1467 21.0406 60.1497 21.057C61.237 26.6682 66.3628 27.1003 66.3628 27.1003C66.3628 27.1003 66.8571 27.1334 66.9639 27.1425C66.9705 27.143 66.9754 27.1432 66.982 27.1432H82.2796C82.2874 27.1432 82.2939 27.143 82.3014 27.1423C82.6014 27.1149 88.8461 26.4715 88.8461 20.3944V11.42C88.8466 11.2927 88.7475 11.1875 88.6204 11.18Z"
			fill="#415BF5"
		/>
		<path
			d="M22.7886 27.1153H53.9419C53.9591 27.1153 53.9766 27.1136 53.9933 27.1101C59.4418 26.0137 60.0118 21.1127 60.0118 21.1127C60.2551 19.7311 60.1339 18.5993 60.1201 18.4809C60.1194 18.4743 60.1187 18.469 60.1175 18.4624C59.9438 17.4621 58.051 13.1358 53.9558 12.7586C51.6819 12.5493 32.7282 11.0575 30.0298 10.8454C29.8697 10.8328 29.7415 10.9788 29.7776 11.1354C30.2293 13.0889 31.1469 14.1037 31.767 14.6969C33.2213 16.0786 35.5023 16.5089 35.678 16.5403C35.6853 16.5415 35.6897 16.5422 35.697 16.5429C36.4058 16.608 52.486 17.9247 52.486 17.9247C53.0026 17.9517 53.9818 18.0811 53.9701 19.3488C53.9701 19.5018 53.8287 20.6198 52.584 20.6198H29.3211C29.1884 20.6198 29.0808 20.5122 29.0808 20.3796V10.9893C29.0808 10.8633 28.9836 10.7587 28.858 10.7496L22.8059 10.3111C22.6667 10.301 22.5483 10.4111 22.5483 10.5508V26.8755C22.5483 27.0077 22.6559 27.1153 22.7886 27.1153Z"
			fill="#415BF5"
		/>
		<path
			d="M88.9094 20.8188C88.9096 20.6864 89.0172 20.5791 89.1496 20.5791H112.251C112.673 20.5791 113.29 20.3402 113.395 20.2397C113.626 20.0184 113.808 19.6378 113.808 19.2712C113.808 18.0063 112.754 17.9515 112.225 17.9245C112.225 17.9245 95.9572 16.5811 95.2628 16.5253C95.256 16.5248 95.2518 16.5241 95.245 16.523C95.0779 16.4958 92.9228 16.1212 91.4657 14.7335C90.842 14.1476 90.0257 13.5108 89.3891 11.5479C89.3369 11.3864 89.4615 11.2212 89.631 11.231C92.4327 11.3948 111.387 12.5067 113.64 12.7153C117.753 13.1048 119.679 17.1309 119.852 18.4706C119.852 18.4706 120.029 19.6814 119.815 21.168C119.815 21.168 118.964 26.8223 112.942 27.1725C112.936 27.1727 112.93 27.173 112.924 27.173H89.1362C89.0033 27.173 88.8955 27.0652 88.896 26.932L88.9094 20.8188Z"
			fill="#415BF5"
		/>
	</svg>
</template>

<script>
export default {
	name: 'Asus',
};
</script>

<style></style>
