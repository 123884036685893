<template>
	<svg
		width="73"
		height="73"
		viewBox="0 0 73 73"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M0 62.9543L29.9196 67.0779V38.5184H0V62.9543Z" fill="#415BF5" />
		<path d="M0 34.833H29.9196V5.92261L0 10.0462V34.833Z" fill="#415BF5" />
		<path
			d="M33.21 67.5168L73.0001 72.9999V38.5184V38.5179H33.21V67.5168Z"
			fill="#415BF5"
		/>
		<path
			d="M33.2102 5.48374V34.833H72.9999V0L33.2102 5.48374Z"
			fill="#415BF5"
		/>
	</svg>
</template>

<script>
export default {
	name: 'Windows',
};
</script>

<style></style>
