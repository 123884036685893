<template>
	<div class="smartbanner" v-if="isShowSmartbanner">
		<div class="smartbanner__close" @click="close"></div>
		<div class="smartbanner__icon"></div>
		<div class="smartbanner__content">
			<div class="smartbanner__info">
				<div class="smartbanner__title">{{ title }}</div>
				<div class="smartbanner__author">{{ author }}</div>
				<div class="smartbanner__price">{{ price }}</div>
			</div>
			<a :href="url" target="__blank" class="smartbanner__button">{{
				button
			}}</a>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';

export default {
	props: {
		url: {
			type: String,
			default: '#',
		},
		title: {
			type: String,
			default: '',
		},
		author: {
			type: String,
			default: '',
		},
		price: {
			type: String,
			default: '',
		},
		button: {
			type: String,
			default: '',
		},
	},
	setup() {
		const isShowSmartbanner = ref(false);
		const temp = localStorage.getItem('smartbaner-show');
		if (temp) {
			isShowSmartbanner.value = false;
		} else {
			isShowSmartbanner.value = true;
		}
		// isShowSmartbanner.value = !!localStorage.getItem("smartbaner-close");

		const close = () => {
			isShowSmartbanner.value = false;
			document
				.querySelectorAll('.space--android')
				.forEach((n) => n.classList.remove('space--android'));
			localStorage.setItem('smartbaner-show', 'false');
		};
		return {
			isShowSmartbanner,
			close,
		};
	},
};
</script>

<style lang="scss" scoped>
.smartbanner {
	display: flex;
	width: 100%;
	background: #3d3d3d
		url(data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7);
	border-top: 4px solid #88b131;
	padding: 10px 20px 10px 10px;
	align-items: center;
	font-family: Helvetica, sans, sans-serif;
	&__title {
		color: #fff;
		font-weight: bold;
		font-size: 14px;
		text-shadow: 0 1px 2px #000;
	}
	&__button {
		color: #d1d1d1;
		font-size: 14px;
		font-weight: bold;
		display: block;
		padding: 0 10px;
		background: #42b6c9;
		background: linear-gradient(to bottom, #42b6c9, #39a9bb);
		box-shadow: none;
		line-height: 24px;
		text-align: center;
		text-shadow: none;
		text-transform: none;
		text-decoration: none;
		box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
		height: 25px;
		margin: auto 0;
	}
	&__author {
		font-size: 12px;
		color: #ccc;
		text-shadow: 0 1px 2px #000;
	}
	&__price {
		font-size: 12px;
		color: #ccc;
		text-shadow: 0 1px 2px #000;
	}
	&__info {
		width: 100%;
	}
	&__icon {
		margin-right: 10px;
		background-image: url(https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/ab/b5/77/abb577ed-a100-6511-0db6-b8f05d928adb/AppIcon-1x_U007emarketing-0-7-0-85-220.png/246x0w.webp);
		min-width: 64px;
		min-height: 64px;
		border-radius: 15px;
		background-size: 64px 64px;
	}
	&__content {
		display: flex;
		width: 100%;
	}
	&__close {
		position: relative;
		top: calc(50% - 6px);
		display: block;
		margin: 0;
		width: 12px;
		height: 12px;
		border: 0;
		text-align: center;
		margin-right: 7px;
		min-width: 18px;
		min-height: 18px;
		border-radius: 14px;
		background: #1c1e21;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset,
			0 1px 1px rgba(255, 255, 255, 0.3);
		color: #b1b1b3;
		font-family: 'ArialRoundedMTBold', Arial;
		font-size: 20px;
		line-height: 17px;
		text-shadow: 0 1px 1px #000;
		&:before {
			content: '';
			position: absolute;
			transform: rotate(45deg);
			top: 3px;
			left: 8px;
			width: 2px;
			height: 11px;
			background: #b1b1b3;
		}
		&:after {
			content: '';
			position: absolute;
			transform: rotate(-45deg);
			top: 3px;
			left: 8px;
			width: 2px;
			height: 11px;
			background: #b1b1b3;
		}
	}
}
@media (max-width: 445px) {
	.smartbanner {
		&__content {
			flex-direction: column;
		}
		&__button {
			width: max-content;
			margin-left: auto;
			margin-top: 5px;
		}
	}
}
</style>
