<template>
	<div
		class="select-dropdown"
		@click="handleToggleShowList"
		v-click-outside="handleClickOutside"
	>
		<div
			:class="{
				'select-dropdown--inner': true,
				active,
			}"
		>
			<div class="select-dropdown--icon">
				<component v-if="selectedItem.icon" :is="selectedItem.icon" />
			</div>
			<div class="select-dropdown--value">
				<span>{{ selectedItem.name }}</span>
			</div>
			<div class="select-dropdown--control-icon">
				<ArrowDropdown class="rotate" />
			</div>
		</div>
		<div v-if="active" class="select-dropdown--list" @click.stop>
			<div
				v-for="item in data"
				:key="item.value"
				@click="handleChangeValue(item)"
				:class="{
					'select-dropdown--list-item': true,
					selected: isSelected(item.value),
				}"
			>
				<div class="select-dropdown--icon">
					<component v-if="item.icon" :is="item.icon" />
				</div>
				<div class="select-dropdown--value">
					<span>{{ item.name }}</span>
				</div>
				<div class="select-dropdown--control-icon">
					<Check v-if="isSelected(item.value)" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';
import ArrowDropdown from '../../icons/ArrowDropdown';
import Check from '../../icons/Check';

/** Features icons */
import Secure from '../../features-icons/Secure';
import Proto from '../../features-icons/Proto';
import Device from '../../features-icons/Device';
import Medal from '../../features-icons/Medal';

export default {
	name: 'SelectDropdown',
	components: {
		ArrowDropdown,
		Check,
		Secure,
		Proto,
		Device,
		Medal,
	},
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Number,
			default: null,
		},
		closeOnChange: Boolean,
	},
	setup(props) {
		const localValue = ref(props.data?.[0]?.value ?? 0);

		const isControlled = props.value !== null;
		const self = getCurrentInstance();

		const active = ref(false);

		const handleToggleShowList = () => {
			active.value = !active.value;
		};

		const handleClickOutside = () => {
			active.value = false;
		};

		const handleChangeValue = (item) => {
			if (!isControlled) {
				localValue.value = item.value;
			}

			self.emit('change', item);

			if (props.closeOnChange) {
				handleToggleShowList();
			}
		};

		const selectedItem = computed(() => {
			const value = isControlled ? props.value : localValue.value;

			return props.data.find((item) => item.value === value);
		});

		const isSelected = (val) => {
			const value = isControlled ? props.value : localValue.value;

			return val === value;
		};

		return {
			handleClickOutside,
			handleToggleShowList,
			handleChangeValue,
			active,
			selectedItem,
			isSelected,
		};
	},
};
</script>
<style lang="scss">
@keyframes toggle-list {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

.select-dropdown {
	width: 100%;
	position: relative;
	z-index: 10;
	cursor: pointer;
	span {
		font-size: 16px;
		line-height: 24px;
		@media (max-width: 320px) {
			font-size: 14px;
		}
	}
	&--inner {
		height: 75px;
		border: 1px solid #c5c5c5;
		border-radius: 6px;
		display: flex;
		padding: 0 26px;
		background-color: #ffffff;
		transition: all 0.3s ease;
		@media (max-width: 320px) {
			height: 60px;
		}
		.rotate {
			transition: all 0.3s ease;
		}
		&.active {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			.rotate {
				transform: rotate(180deg);
			}
		}
	}
	&--value {
		flex: 1 0 0%;
		padding: 0 15px;
		display: flex;
		align-items: center;
	}
	&--icon,
	&--control-icon {
		display: flex;
		align-items: center;
		flex: 0 0 0%;
	}
	&--icon {
		svg path {
			fill: #415bf5;
		}
	}
	&--list {
		width: 100%;
		border: 1px solid #c5c5c5;
		border-top: none;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		overflow: hidden;
		animation: toggle-list 0.3s ease;
	}
	&--list-item {
		display: flex;
		padding: 0 26px;
		height: 75px;
		@media (max-width: 320px) {
			height: 60px;
		}
		cursor: pointer;
		background-color: #ffffff;
		transition: all 0.3s ease;
		.select-dropdown--icon {
			svg path {
				fill: #c5c5c5;
			}
		}
		span {
			color: #6b6b6b;
			font-weight: 400;
		}
		&.selected {
			background-color: #f5f5f5;
			span {
				color: #000000;
			}
			.select-dropdown--icon {
				svg path {
					fill: #415bf5;
				}
			}
		}
		&:not(:last-child) {
			border-bottom: 1px solid #c5c5c5;
		}
		&:hover {
			background-color: #f5f5f5;
			span {
				color: #000000;
			}
			.select-dropdown--icon {
				svg path {
					fill: #415bf5;
				}
			}
		}
	}
}
</style>
