<template>
	<div class="row">
		<slot />
	</div>
</template>
<style scoped lang="scss">
.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
}
</style>
