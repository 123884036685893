<template>
	<svg
		width="116"
		height="44"
		viewBox="0 0 116 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M31.8781 0.443999C37.2538 1.9936 42.7494 7.25953 43.5143 13.9252C44.1379 19.3624 42.5868 24.058 38.7506 27.9164C35.7007 30.9845 31.9188 32.4503 27.6257 32.5925C26.6613 32.6256 25.6931 32.5672 24.7287 32.608C24.1284 32.6353 23.9599 32.4114 23.9657 31.8254C23.9909 29.2324 23.9948 26.6393 23.9638 24.0463C23.9561 23.4214 24.1942 23.3046 24.7539 23.2948C26.2624 23.2695 27.7806 23.47 29.2755 23.1021C32.4571 22.3156 34.4769 19.5532 34.442 16.0257C34.4072 12.5878 32.5075 10.0531 29.3472 9.38148C24.3221 8.31272 20.2477 12.0933 20.4569 16.491C20.4975 17.332 20.4278 18.1788 20.4743 19.0178C20.5072 19.6311 20.269 19.7868 19.6939 19.781C17.1184 19.7557 14.541 19.7712 11.9655 19.7693C11.613 19.7693 11.247 19.8296 11.2316 19.2709C11.1347 15.7162 11.2761 12.2101 12.9802 8.95904C15.4124 4.3219 19.202 1.57895 24.2523 0.309673C25.7899 -0.0777277 29.7364 -0.173118 31.8781 0.443999Z"
			fill="#415BF5"
		/>
		<path
			d="M0 23.1098C2.08751 23.0904 4.17502 23.0612 6.26253 23.0573C10.7629 23.0495 15.2632 23.0553 19.7655 23.0553C20.238 23.0553 20.724 22.9366 20.7202 23.7347C20.6872 30.3984 20.6872 37.0641 20.6756 43.7277C19.2485 43.936 17.8155 43.7861 16.3844 43.8173C15.1393 43.8446 13.8941 43.8231 12.649 43.8231C11.3787 43.6246 10.7648 42.914 10.7358 41.4734C10.6893 39.2717 10.7241 37.0699 10.7203 34.8681C10.7164 33.1336 10.7183 33.1336 8.96002 33.1316C6.83766 33.1297 4.71335 33.1511 2.59099 33.1239C1.11734 33.1044 0.515099 32.6547 0 31.2803C0 28.5568 0 25.8333 0 23.1098Z"
			fill="#415BF5"
		/>
		<path
			d="M12.562 43.9223C12.56 43.8581 12.5581 43.7938 12.5562 43.7296C15.2633 43.7277 17.9686 43.7277 20.6757 43.7257C20.6777 43.7899 20.6777 43.8561 20.6796 43.9204C17.9744 43.9223 15.2691 43.9223 12.562 43.9223Z"
			fill="#415BF5"
		/>
		<path
			d="M58.3301 30.1395C58.3301 28.0662 58.3282 25.9929 58.332 23.9177C58.332 23.6004 58.2797 23.2772 58.5179 22.995C60.1484 21.0599 62.2534 20.1294 64.7359 20.2734C67.2553 20.4214 69.1239 21.7101 70.1057 24.0832C71.897 28.4166 68.9787 32.7851 64.3041 32.8318C64.2073 32.8338 64.1104 32.8318 64.0136 32.8318C62.2456 32.8357 62.2631 32.8357 62.3231 31.0915C62.3386 30.6437 62.4277 30.4373 62.9137 30.4802C63.361 30.5211 63.8142 30.4996 64.2654 30.486C65.9985 30.4393 67.2959 29.3803 67.7413 27.6652C68.1944 25.9229 67.4915 24.0929 66.0546 23.2792C64.6643 22.4908 62.6349 22.7672 61.4691 23.8652C61.0954 24.2175 61.0353 24.6108 61.0353 25.0838C61.045 28.9072 61.0179 32.7306 61.0605 36.554C61.0683 37.3308 60.8533 37.6111 60.102 37.4943C59.8502 37.4553 59.5869 37.4709 59.3312 37.4923C58.5741 37.5546 58.3069 37.2003 58.3185 36.4567C58.3475 34.3522 58.3301 32.2459 58.3301 30.1395Z"
			fill="#415BF5"
		/>
		<path
			d="M116 32.8338C114.863 32.8338 113.94 32.8143 113.018 32.8416C112.571 32.8552 112.412 32.5301 112.195 32.2653C110.733 30.4996 109.275 28.7281 107.809 26.9507C107.474 27.1123 107.638 27.4063 107.635 27.6243C107.617 29.1155 107.594 30.6067 107.64 32.096C107.66 32.7111 107.46 32.8474 106.879 32.8474C104.891 32.8533 104.891 32.8766 104.891 30.8325C104.891 26.2966 104.914 21.7607 104.867 17.2248C104.86 16.4461 105.096 16.0432 105.787 15.7784C106.378 15.5526 106.932 15.2314 107.594 14.9082C107.594 18.5272 107.594 22.0411 107.594 25.6639C108.088 25.4673 108.276 25.0858 108.525 24.7957C109.534 23.6179 110.559 22.4518 111.507 21.2273C111.972 20.6277 112.478 20.3708 113.225 20.4428C113.854 20.5031 114.494 20.4545 115.361 20.4545C114.98 20.9119 114.732 21.2215 114.472 21.5193C113.241 22.9307 112.026 24.3596 110.758 25.7399C110.33 26.2051 110.38 26.4757 110.777 26.9176C112.485 28.8099 114.157 30.7391 116 32.8338Z"
			fill="#415BF5"
		/>
		<path
			d="M90.8277 27.5424C90.8277 26.3121 90.88 25.0779 90.8084 23.8514C90.7619 23.0513 91.0659 22.514 91.6178 22.0001C93.6414 20.1215 96.825 19.5666 99.1545 20.758C101.019 21.7119 102.127 23.2674 102.241 25.3601C102.365 27.6534 102.286 29.9583 102.321 32.2574C102.328 32.7441 102.139 32.8298 101.701 32.8473C99.6464 32.9291 99.6483 32.9427 99.6483 30.8908C99.6483 29.3042 99.6716 27.7157 99.6386 26.1291C99.6115 24.7781 99.1022 23.649 97.8435 23.0299C96.61 22.4225 95.4075 22.6269 94.2766 23.3569C93.7537 23.6937 93.5155 24.124 93.5291 24.8014C93.5775 27.231 93.5291 29.6605 93.562 32.09C93.5698 32.6701 93.4439 32.857 92.8262 32.8551C90.8277 32.8473 90.8277 32.8765 90.8277 30.8499C90.8277 29.7442 90.8277 28.6443 90.8277 27.5424Z"
			fill="#415BF5"
		/>
		<path
			d="M49.4573 24.157C49.4573 22.44 49.4999 20.723 49.4379 19.0099C49.4108 18.274 49.6799 17.9392 50.3132 17.6394C52.1857 16.7517 52.1722 16.7224 52.1722 18.7899C52.1722 20.4543 52.1722 20.4544 53.824 20.4544C54.0486 20.4544 54.281 20.4894 54.4979 20.4485C55.501 20.2558 55.7508 20.6919 55.6849 21.6516C55.5978 22.9033 55.6636 22.9072 54.3836 22.9072C52.1722 22.9072 52.1722 22.9072 52.1722 25.1655C52.1722 26.2985 52.1644 27.4315 52.1741 28.5664C52.1877 30.0712 52.5866 30.4781 54.0544 30.4917C54.6973 30.4976 55.346 30.5365 55.9831 30.4801C56.6028 30.4236 56.7248 30.665 56.7209 31.2412C56.7112 32.8356 56.7384 32.8356 55.1737 32.8337C54.401 32.8337 53.6284 32.8551 52.8577 32.822C50.6908 32.7305 49.4882 31.4865 49.4631 29.3042C49.4437 27.5892 49.4573 25.8721 49.4573 24.157Z"
			fill="#415BF5"
		/>
		<path
			d="M82.6483 14.8888C82.6483 20.7874 82.6444 26.5167 82.656 32.244C82.658 32.7229 82.4856 32.8261 82.0402 32.8455C79.9217 32.939 79.9217 32.9526 79.9217 30.8715C79.9217 26.3687 79.9547 21.8679 79.8946 17.365C79.883 16.4637 80.1677 16.0023 80.9578 15.7084C81.4903 15.5098 81.988 15.2139 82.6483 14.8888Z"
			fill="#415BF5"
		/>
		<path
			d="M88.1031 26.61C88.1031 28.4556 88.0838 30.3011 88.1148 32.1466C88.1244 32.7053 87.9444 32.8416 87.408 32.8493C85.3863 32.8785 85.3863 32.9 85.3863 30.8812C85.3863 27.6769 85.4018 24.4706 85.3727 21.2662C85.3669 20.6491 85.5063 20.4311 86.1647 20.435C88.1031 20.4467 88.1031 20.4155 88.1031 22.335C88.1031 23.76 88.1031 25.185 88.1031 26.61Z"
			fill="#415BF5"
		/>
		<path
			d="M72.6772 25.259C74.1644 25.259 75.6323 25.2667 77.1001 25.2531C77.4526 25.2492 77.532 25.4166 77.5261 25.7242C77.5145 26.3063 77.4971 26.8903 77.5319 27.4705C77.5591 27.926 77.3461 28.0194 76.9588 27.9941C76.6063 27.9708 76.252 27.9902 75.8976 27.9902C73.6242 27.9902 73.3008 27.7216 72.6772 25.259Z"
			fill="#415BF5"
		/>
		<path
			d="M86.7014 17.6434C85.2413 17.6434 84.8947 17.4195 84.8753 16.4695C84.856 15.4903 85.7099 14.626 86.7092 14.6124C87.7258 14.5987 88.5992 15.4125 88.5372 16.4267C88.483 17.3183 88.5876 17.7739 86.7014 17.6434Z"
			fill="#415BF5"
		/>
	</svg>
</template>

<script>
export default {
	name: 'TPLink',
};
</script>

<style></style>
