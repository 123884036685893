<template>
	<svg
		width="74"
		height="56"
		viewBox="0 0 74 56"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M67.2934 34.5191H49.3862L73.2311 8.02041C74.0248 7.13511 73.9445 5.7758 73.0517 4.98917C72.1567 4.19838 70.7889 4.27801 70.0016 5.16746L43.605 34.5191H30.173L3.77621 5.16746C2.98469 4.27801 1.61691 4.19824 0.726106 4.98917C-0.16672 5.7758 -0.246983 7.13511 0.546705 8.02041L24.3916 34.5191H6.48438C2.90875 34.5191 0 37.4099 0 40.9634V49.5557C0 53.1093 2.90875 56 6.48438 56H67.2934C70.869 56 73.7778 53.1093 73.7778 49.5557V40.9634C73.7778 37.4099 70.869 34.5191 67.2934 34.5191ZM41.2118 47.4077C40.0181 47.4077 39.0503 46.4459 39.0503 45.2596C39.0503 44.0732 40.0181 43.1115 41.2118 43.1115C42.4055 43.1115 43.3733 44.0732 43.3733 45.2596C43.3733 46.4459 42.4055 47.4077 41.2118 47.4077ZM49.8576 47.4077C48.6639 47.4077 47.6962 46.4459 47.6962 45.2596C47.6962 44.0732 48.6639 43.1115 49.8576 43.1115C51.0513 43.1115 52.0191 44.0732 52.0191 45.2596C52.0191 46.4459 51.0513 47.4077 49.8576 47.4077ZM58.5035 47.4077C57.3098 47.4077 56.342 46.4459 56.342 45.2596C56.342 44.0732 57.3098 43.1115 58.5035 43.1115C59.6972 43.1115 60.6649 44.0732 60.6649 45.2596C60.6649 46.4459 59.6972 47.4077 58.5035 47.4077Z"
			fill="#415BF5"
		/>
		<path
			d="M53.3492 9.6703C54.1737 8.8099 54.1462 7.44353 53.286 6.61697C48.8368 2.34898 43 0 36.8512 0C30.7023 0 24.8656 2.34898 20.4163 6.61697C19.556 7.44353 19.5286 8.8099 20.3532 9.6703C21.1777 10.5327 22.5441 10.5559 23.4065 9.73349C27.046 6.24154 31.822 4.31851 36.8512 4.31851C41.8804 4.31851 46.6563 6.24154 50.2958 9.73349C51.1644 10.5628 52.5261 10.5261 53.3492 9.6703Z"
			fill="#415BF5"
		/>
		<path
			d="M47.3608 15.8863C48.1854 15.0259 48.1557 13.6576 47.2933 12.833C41.6421 7.4222 32.0605 7.4222 26.4093 12.833C25.5469 13.6576 25.5173 15.0259 26.3418 15.8863C27.1663 16.7487 28.5369 16.7762 29.3951 15.9538C33.4352 12.0866 40.2672 12.0866 44.3075 15.9538C45.1657 16.7772 46.536 16.748 47.3608 15.8863Z"
			fill="#415BF5"
		/>
		<path
			d="M32.4083 19.039C31.5396 19.8572 31.4996 21.2236 32.3177 22.0902C33.1315 22.9611 34.5043 23.0011 35.3688 22.1809C36.1744 21.4218 37.5281 21.4218 38.3336 22.1809C39.195 22.9985 40.568 22.9598 41.3848 22.0902C42.203 21.2235 42.1628 19.8571 41.2941 19.039C38.8818 16.7616 34.8207 16.7616 32.4083 19.039Z"
			fill="#415BF5"
		/>
	</svg>
</template>

<script>
export default {
	name: 'Router',
};
</script>

<style></style>
