<template>
	<svg
		:width="size"
		:height="size"
		viewBox="0 0 32 31"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M24.75 7.6875C24.8992 7.6875 25.0423 7.62824 25.1477 7.52275C25.2532 7.41726 25.3125 7.27418 25.3125 7.125H20.8125C20.8125 7.27418 20.8718 7.41726 20.9773 7.52275C21.0827 7.62824 21.2258 7.6875 21.375 7.6875H24.75Z"
			fill="#ADADAD"
		/>
		<path
			d="M27 7.125H26.4375C26.4375 7.57255 26.2597 8.00178 25.9432 8.31824C25.6268 8.63471 25.1976 8.8125 24.75 8.8125H21.375C20.9274 8.8125 20.4982 8.63471 20.1818 8.31824C19.8653 8.00178 19.6875 7.57255 19.6875 7.125H19.125C18.9758 7.125 18.8327 7.18426 18.7273 7.28975C18.6218 7.39524 18.5625 7.53832 18.5625 7.6875V23.4375C18.5625 23.5867 18.6218 23.7298 18.7273 23.8352C18.8327 23.9407 18.9758 24 19.125 24H27C27.1492 24 27.2923 23.9407 27.3977 23.8352C27.5032 23.7298 27.5625 23.5867 27.5625 23.4375V7.6875C27.5625 7.53832 27.5032 7.39524 27.3977 7.28975C27.2923 7.18426 27.1492 7.125 27 7.125Z"
			fill="#ADADAD"
		/>
		<path
			d="M3.9375 15C3.78832 15 3.64524 15.0593 3.53975 15.1648C3.43426 15.2702 3.375 15.4133 3.375 15.5625V25.6875C3.375 25.8367 3.43426 25.9798 3.53975 26.0852C3.64524 26.1907 3.78832 26.25 3.9375 26.25H21.9375C22.0867 26.25 22.2298 26.1907 22.3352 26.0852C22.4407 25.9798 22.5 25.8367 22.5 25.6875V25.125H19.125C18.6774 25.125 18.2482 24.9472 17.9318 24.6307C17.6153 24.3143 17.4375 23.8851 17.4375 23.4375V15H3.9375Z"
			fill="#ADADAD"
		/>
		<path
			d="M28.6875 19.5H30.9375C31.0867 19.5 31.2298 19.4407 31.3352 19.3352C31.4407 19.2298 31.5 19.0867 31.5 18.9375V17.25H28.6875V19.5Z"
			fill="#ADADAD"
		/>
		<path
			d="M2.25 17.25H0V18.9375C0 19.0867 0.0592633 19.2298 0.164753 19.3352C0.270242 19.4407 0.413316 19.5 0.5625 19.5H2.25V17.25Z"
			fill="#ADADAD"
		/>
		<path
			d="M30.9375 0.375H0.5625C0.413316 0.375 0.270242 0.434263 0.164753 0.539753C0.0592633 0.645242 0 0.788316 0 0.9375V16.125H2.25V15.5625C2.25 15.1149 2.42779 14.6857 2.74426 14.3693C3.06072 14.0528 3.48995 13.875 3.9375 13.875H17.4375V7.6875C17.4375 7.23995 17.6153 6.81072 17.9318 6.49426C18.2482 6.17779 18.6774 6 19.125 6H27C27.4476 6 27.8768 6.17779 28.1932 6.49426C28.5097 6.81072 28.6875 7.23995 28.6875 7.6875V16.125H31.5V0.9375C31.5 0.788316 31.4407 0.645242 31.3352 0.539753C31.2298 0.434263 31.0867 0.375 30.9375 0.375Z"
			fill="#ADADAD"
		/>
		<path
			d="M24.1172 27.9375H14.625C14.4758 27.9375 14.3327 27.9968 14.2273 28.1023C14.1218 28.2077 14.0625 28.3508 14.0625 28.5H11.8125C11.8125 28.3508 11.7532 28.2077 11.6477 28.1023C11.5423 27.9968 11.3992 27.9375 11.25 27.9375H1.75781C1.67049 27.9375 1.58436 27.9578 1.50625 27.9969C1.42815 28.0359 1.36021 28.0926 1.30781 28.1625C1.25542 28.2324 1.22001 28.3135 1.20439 28.3994C1.18876 28.4853 1.19336 28.5737 1.21781 28.6575L1.47375 29.535C1.5772 29.8848 1.79068 30.192 2.08251 30.4108C2.37434 30.6297 2.72896 30.7486 3.09375 30.75H22.7812C23.146 30.7486 23.5007 30.6297 23.7925 30.4108C24.0843 30.192 24.2978 29.8848 24.4013 29.535L24.6572 28.6575C24.6816 28.5737 24.6862 28.4853 24.6706 28.3994C24.655 28.3135 24.6196 28.2324 24.5672 28.1625C24.5148 28.0926 24.4468 28.0359 24.3687 27.9969C24.2906 27.9578 24.2045 27.9375 24.1172 27.9375Z"
			fill="#ADADAD"
		/>
	</svg>
</template>
<script>
export default {
	name: 'Device',
	inheritAttrs: false,
	props: {
		size: {
			type: String,
			default: '30',
		},
	},
};
</script>
