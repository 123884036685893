<template>
	<button class="btn-icon" v-bind="$attrs">
		<component v-if="validPropIconName" :is="icon" class="icon" />
	</button>
</template>
<script>
import { computed } from 'vue';
const TIcons = ['Arrow', 'Eye'];

export default {
	name: 'ButtonIcon',
	props: {
		icon: String,
	},
	setup(props, context) {
		const validPropIconName = computed(() => {
			if (!props.icon.length || !TIcons.includes(props.icon)) {
				console.warn(
					`prop "icon" can only be "${TIcons.join(', ')}". Now "${props.icon}"`
				);
				return false;
			}
			return true;
		});
		return {
			validPropIconName,
		};
	},
};
</script>
<style scoped lang="scss">
.btn-icon {
	padding: 0;
	margin: 0;
	outline: none;
	border: none;
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
</style>
<style lang="scss">
.btn-icon:disabled {
	cursor: not-allowed;
	.icon path {
		fill: #c5c5c5;
	}
}
</style>
