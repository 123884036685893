import { createApp, computed } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { Helper } from './helpers';
import App from './App.vue';
import router from './router';
import store from './store';
import * as components from './components';
import { ClickOutside, VFocus } from './derectives';
import Cookies from 'js-cookie';
import './styles/fonts.scss';

let temp_locale = Cookies.get('dvpn_auth_locale') || 'en';

const watchToken = () => {
	const isAuth = store.getters['AuthStore/isAuthCheck'];
	const token = store.getters['AuthStore/token'];
	const dynamicToken = Cookies.get('dvpn_auth_token');

	const domain_locale = Cookies.get('dvpn_auth_locale') || 'en';

	if (temp_locale.length && temp_locale !== domain_locale) {
		temp_locale = domain_locale;
		window.translate = translate();
		location.href = '/';
	}

	if (isAuth && !dynamicToken && !token) {
		store.dispatch('AuthStore/logout');
	} else if (!isAuth && dynamicToken && token && dynamicToken === token) {
		store.dispatch('AuthStore/setIsAuth', true);
	} else if (!isAuth && dynamicToken && token && dynamicToken !== token) {
		store.dispatch('AuthStore/setCurrentToken', dynamicToken);
		store.dispatch('AuthStore/setIsAuth', true);
	} else if (isAuth && !dynamicToken && token) {
		store.dispatch('AuthStore/logout');
	} else if (!isAuth && dynamicToken && !token) {
		store.dispatch('AuthStore/setCurrentToken', dynamicToken);
		store.dispatch('AuthStore/setIsAuth', true);
	}

	setTimeout(watchToken, 600);
};

const translate = () => {
	let locale = Cookies.get('dvpn_auth_locale') || 'en';
	temp_locale = locale;
	if (!locale) {
		const lang = navigator.language || navigator.userLanguage;
		locale = lang.split('-')[0];
		localStorage.setItem('locale', locale);
	}

	const locales = computed(() => {
		const path = require.context('@/locales', false, /\.json$/);
		const keys = path.keys();
		if (keys.length) {
			let locales = {};
			keys.forEach((key) => {
				locales[key.replace(/.\/|.json/g, '')] = path(key);
			});
			return locales;
		} else {
			return [];
		}
	});

	window.locales = Object.keys(locales.value);

	if (locales.value[locale]) {
		return locales.value[locale];
	} else {
		return locales.value['en'];
	}
};

watchToken();
window.translate = translate();

const app = createApp(App);
if (process.env.VUE_APP_SENTRY_ENVIRONMENT !== 'local') {
	Sentry.init({
		app,
		dsn: `https://7335f3407e334657a438de19edd0ca2c@${process.env.VUE_APP_SENTRY_DOMAIN}/6`,
		environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
		logErrors: process.env.VUE_APP_SENTRY_LOG,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: [
					'dvpn-landing.projects.dl-net.ru',
					'dubki-vpn.ru',
					/^\//,
				],
			}),
		],
		tracesSampleRate: 1.0,
	});
}
app.use(store);
app.use(router);
app.provide('translate', translate());

app.directive('click-outside', ClickOutside);
app.directive('focus', VFocus);

Helper.registerComponents(app, components);

app.config.globalProperties.$filters = {
	strings: Helper.strings,
};

app.mount('#app');
