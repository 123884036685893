<template>
	<svg
		width="56"
		height="68"
		viewBox="0 0 56 68"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M41.2813 25.2351H15.3824C14.6489 25.2351 14.0543 25.8297 14.0543 26.5632C14.0543 27.2967 14.6489 27.8913 15.3824 27.8913H41.2813C42.0147 27.8913 42.6094 27.2967 42.6094 26.5632C42.6094 25.8297 42.0148 25.2351 41.2813 25.2351Z"
			fill="#415BF5"
		/>
		<path
			d="M41.2811 31.2119H9.40624C8.67272 31.2119 8.07812 31.8065 8.07812 32.54C8.07812 33.2736 8.67272 33.8682 9.40624 33.8682H41.2811C42.0145 33.8682 42.6092 33.2736 42.6092 32.54C42.6092 31.8065 42.0146 31.2119 41.2811 31.2119Z"
			fill="#415BF5"
		/>
		<path
			d="M9.40637 21.9148H41.2813C42.0146 21.9148 42.6094 21.3202 42.6094 20.5867C42.6094 19.8531 42.0146 19.2585 41.2813 19.2585H9.40637C8.67285 19.2585 8.07825 19.8531 8.07825 20.5867C8.07825 21.3202 8.67285 21.9148 9.40637 21.9148Z"
			fill="#415BF5"
		/>
		<path
			d="M25.3438 37.1887H9.40631C8.67279 37.1887 8.07819 37.7833 8.07819 38.5168C8.07819 39.2504 8.67279 39.845 9.40631 39.845H25.3438C26.0773 39.845 26.6719 39.2504 26.6719 38.5168C26.6719 37.7833 26.0773 37.1887 25.3438 37.1887Z"
			fill="#415BF5"
		/>
		<path
			d="M17.3751 43.1655H9.40631C8.67279 43.1655 8.07819 43.7601 8.07819 44.4937C8.07819 45.2272 8.67279 45.8218 9.40631 45.8218H17.3751C18.1086 45.8218 18.7032 45.2272 18.7032 44.4937C18.7032 43.7601 18.1086 43.1655 17.3751 43.1655Z"
			fill="#415BF5"
		/>
		<path
			d="M9.4064 27.8913C10.1399 27.8913 10.7345 27.2967 10.7345 26.5632C10.7345 25.8297 10.1399 25.2351 9.4064 25.2351H9.40547C8.67195 25.2351 8.07788 25.8297 8.07788 26.5632C8.07788 27.2967 8.67288 27.8913 9.4064 27.8913Z"
			fill="#31C6B4"
		/>
		<path
			d="M50.5781 42.1294V2.65624C50.5781 1.19159 49.3865 0 47.9218 0H14.7192C14.367 0 14.0291 0.139984 13.7801 0.389006L0.498381 13.6706C0.249359 13.9196 0.109375 14.2575 0.109375 14.6097V60.0311C0.109375 61.4958 1.30096 62.6874 2.76561 62.6874H30.0197C32.7014 65.9303 36.7541 68 41.2811 68C49.3368 68 55.8905 61.4463 55.8905 53.3907C55.8905 48.8638 53.8208 44.8113 50.5781 42.1294ZM13.3911 4.53447V13.2816H4.64397L13.3911 4.53447ZM2.76575 60.0311V15.9378H13.391C14.8556 15.9378 16.0472 14.7462 16.0472 13.2816V2.65624H47.9218V40.3798C45.9283 39.3582 43.6708 38.7814 41.2811 38.7814C33.2255 38.7814 26.6718 45.3351 26.6718 53.3907C26.6718 55.7802 27.2484 58.0376 28.27 60.0311H2.76575ZM41.2811 65.3438C34.6902 65.3438 29.3281 59.9816 29.3281 53.3907C29.3281 46.7998 34.6902 41.4376 41.2811 41.4376C47.8722 41.4376 53.2343 46.7998 53.2343 53.3907C53.2343 59.9816 47.8722 65.3438 41.2811 65.3438Z"
			fill="#415BF5"
		/>
		<path
			d="M46.5936 49.4062H35.9688C33.7716 49.4062 31.9843 51.1936 31.9843 53.3906C31.9843 55.5876 33.7716 57.375 35.9688 57.375H46.5936C48.7907 57.375 50.5781 55.5876 50.5781 53.3906C50.5781 51.1936 48.7907 49.4062 46.5936 49.4062ZM46.5936 54.7188H35.9688C35.2363 54.7188 34.6405 54.123 34.6405 53.3906C34.6405 52.6583 35.2363 52.0625 35.9688 52.0625H46.5936C47.3261 52.0625 47.9219 52.6583 47.9219 53.3906C47.9219 54.123 47.3261 54.7188 46.5936 54.7188Z"
			fill="#31C6B4"
		/>
	</svg>
</template>
<script>
export default {
	name: 'NoLogs',
};
</script>
<style scoped lang="scss"></style>
