<template>
	<div class="navbar header fixible">
		<Smartbanner
			v-if="checkAndroid"
      title="DubkiVPN. Fast & Secure VPN."
      author="DLine Media"
      price="БЕСПЛАТНО - в Google Play"
      button="Смотреть"
			url="https://play.google.com/store/apps/details?id=ru.dubki.vpn"
		/>
		<Container
			fullHeight
			class="header-container d-flex"
			style="background-color: #415bf5; height: 89px"
		>
			<Logo class="header-logo" />
			<HeaderMenu />
			<HeaderControl />
		</Container>
	</div>
</template>

<script>
import HeaderMenu from './HeaderMenu';
import { Smartbanner } from '@/components/organisms/Smartbanner';
import HeaderControl from './HeaderControl';
import { computed, onMounted, onUnmounted, ref } from '@vue/runtime-core';

export default {
	name: 'Header',
	components: {
		HeaderMenu,
		HeaderControl,
		Smartbanner,
	},
	setup() {
		const wScrollY = ref(0);

		const windowOnScroll = () => {
			wScrollY.value = window.scrollY;
		};

		onMounted(() => window.addEventListener('scroll', windowOnScroll));

		onUnmounted(() => window.removeEventListener('scroll', windowOnScroll));

		const checkAndroid = computed(() => {
			var ua = navigator.userAgent.toLowerCase();
			return ua.indexOf('android') > -1;
		});

		const classes = computed(() => {
			let className = 'header';

			if (wScrollY.value > 89) {
				className = `${className} fixible`;
			}

			return className;
		});

		return {
			checkAndroid,
			classes,
		};
	},
};
</script>
<style scoped lang="scss">
.header {
	width: 100%;
	background-color: #415bf5;
	transition: 0.3s ease;
	z-index: 100;
	&.fixible {
		position: fixed;
		top: 0;
		left: 0;
	}
	&-container {
		display: flex;
		align-items: center;
	}
	&-logo {
		flex: 0 1 auto;
		display: flex;
	}
}
</style>
