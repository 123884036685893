import { notificationStore } from '@/store/modules/Notification';

const personalAreaUrl = process.env.VUE_APP_DVPN_LK;

export const successPaidMessage = () =>
	notificationStore
		.show({
			title: window.translate.notification.successPaid.title,
			content: window.translate.notification.successPaid.description,
			icon: 'success',
			buttonText: window.translate.notification.successPaid.button,
		})
		.then(() => {
			const name = localStorage.getItem('cart_name');
			const variation = localStorage.getItem('cart_variation');
			const price = parseInt(localStorage.getItem('cart_price'));
			const quantity = parseInt(localStorage.getItem('cart_quanity'));
			const payment_id = localStorage.getItem('cart_payment');

			window.ym(45609672, 'reachGoal', 'landing_successful_payment');
			window.dataLayer.push({
				ecommerce: {
					currencyCode: 'RUB',
					purchase: {
						actionField: {
							id: payment_id,
						},
						products: {
							name: name,
							variant: variation,
							price: price,
							quantity: quantity,
						},
					},
				},
			});
		});

export const errorPaidMessage = () =>
	notificationStore.show({
		title: window.translate.notification.errorPaid.title,
		content: window.translate.notification.errorPaid.description,
		icon: 'error',
		buttonText: window.translate.notification.errorPaid.button,
	});

export const pressTryItNow = () =>
	notificationStore.show({
		title: window.translate.notification.tryItNow.title,
		content: window.translate.notification.tryItNow.description,
		icon: 'warning',
		buttonText: window.translate.notification.tryItNow.button,
	});

export const newAccountLogin = () =>
	notificationStore
		.show({
			title: window.translate.notification.newAccount.title,
			content: window.translate.notification.newAccount.description,
			icon: 'success',
			buttonText: window.translate.notification.newAccount.button,
		})
		.then((result) => {
			if (result.isConfirmed) {
				window.location.href = personalAreaUrl;
			}
		});

export const trialBlocking = () =>
	notificationStore.show({
		title: window.translate.notification.trialBlocking.title,
		content: window.translate.notification.trialBlocking.description,
		icon: 'warning',
		buttonText: window.translate.notification.trialBlocking.button,
	});
