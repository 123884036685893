<template>
	<svg
		width="75"
		height="75"
		viewBox="0 0 75 75"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="37.5" cy="37.5" r="37.5" fill="#415BF5" />
		<path
			d="M31.0228 21.378C30.0328 21.378 28.5748 21.738 27.9268 23.394C27.4588 22.098 26.3248 21.378 24.9748 21.378C23.7328 21.378 22.5088 21.972 22.0948 23.088V21.594H20.9428V30H22.0948V25.338C22.1308 23.322 23.4268 22.494 24.7768 22.494C26.1088 22.494 27.0808 23.52 27.0808 25.158V30H28.2148V25.716C28.2148 23.466 29.2588 22.476 30.8428 22.494C32.2288 22.494 33.2008 23.538 33.2008 25.158V30H34.3528V25.104C34.3528 22.782 33.0568 21.378 31.0228 21.378ZM44.1702 23.232C43.5402 22.08 42.2442 21.378 40.8042 21.378C38.4102 21.378 36.5562 23.34 36.5562 25.788C36.5562 28.29 38.4822 30.216 40.7682 30.216C42.2622 30.216 43.5582 29.406 44.1702 28.254V30H45.3222V21.594H44.1702V23.232ZM40.9122 29.118C39.1302 29.118 37.6902 27.606 37.6902 25.788C37.6902 23.988 39.1302 22.476 40.8942 22.476C42.5862 22.476 44.1702 23.862 44.1702 25.824C44.1702 27.714 42.6582 29.118 40.9122 29.118ZM52.0765 30.216C53.2645 30.216 54.3445 29.784 55.1005 29.064L54.3805 28.272C53.7865 28.794 52.9945 29.118 52.1125 29.118C50.2585 29.118 48.7645 27.75 48.7645 25.788C48.7645 23.808 50.2585 22.476 52.1125 22.476C52.9945 22.476 53.8045 22.8 54.3805 23.322L55.0825 22.53C54.3445 21.828 53.2645 21.378 52.0765 21.378C49.6645 21.378 47.6125 23.124 47.6125 25.788C47.6125 28.452 49.6645 30.216 52.0765 30.216Z"
			fill="white"
		/>
		<path
			d="M29.3799 55.312C34.4239 55.312 38.5059 51.1 38.5059 46.03C38.5059 40.96 34.4239 36.774 29.3799 36.774C24.2579 36.774 20.2279 40.96 20.2279 46.03C20.2279 51.1 24.2579 55.312 29.3799 55.312ZM29.3539 51.464C26.3379 51.464 24.3359 49.02 24.3359 46.03C24.3359 43.04 26.3379 40.622 29.3539 40.622C32.3439 40.622 34.3979 43.04 34.3979 46.03C34.3979 49.02 32.3439 51.464 29.3539 51.464ZM47.1184 55.312C50.3944 55.312 53.7224 53.596 53.7224 49.748C53.7224 46.498 51.1224 45.198 48.5744 44.418L46.7024 43.82C45.0384 43.352 44.9344 42.546 44.9344 42.182C44.9344 41.194 45.7664 40.336 47.0404 40.336C48.4444 40.336 49.0944 41.246 49.0944 42.312H53.1764C53.1764 38.828 50.5504 36.774 47.1184 36.774C43.5564 36.774 40.8004 39.036 40.8004 42.26C40.8004 43.95 41.5544 46.368 45.5064 47.434L47.6384 48.084C49.2244 48.604 49.6144 49.254 49.6144 49.956C49.6144 51.126 48.6004 51.802 47.2744 51.802C45.8704 51.802 44.7264 50.918 44.7264 49.774H40.6444C40.6444 53.076 43.2964 55.312 47.1184 55.312Z"
			fill="white"
		/>
	</svg>
</template>

<script>
export default {
	name: 'MacOS',
};
</script>

<style></style>
