<template>
	<svg
		width="88"
		height="85"
		viewBox="0 0 88 85"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M75.1349 30.0788C78.4762 18.3992 64.8281 8.23996 54.5381 14.9163C51.8525 6.15739 43.6844 0 34.3643 0C20.7339 0 11.1265 12.5289 13.6561 25.1636C6.12164 25.235 0 31.3286 0 38.8989C0 46.46 6.15258 52.6114 13.7137 52.6114C14.4737 52.6114 15.0887 51.9956 15.0887 51.2364C15.0887 50.4771 14.4737 49.8614 13.7137 49.8614C7.66851 49.8612 2.75 44.9434 2.75 38.8989C2.75 32.0561 8.97916 27.0091 15.2257 28.0493C15.6889 28.1292 16.1682 27.9587 16.4825 27.6029C16.7953 27.2477 16.9041 26.7556 16.7685 26.3003C13.2861 14.6039 22.054 2.75 34.3643 2.75C43.0977 2.75 50.6682 8.96638 52.3628 17.5319C52.4594 18.014 52.8045 18.4081 53.2691 18.5665C53.7338 18.7237 54.2494 18.6229 54.6186 18.2986C63.492 10.52 77.1139 20.7644 71.6275 31.4901C71.3657 32.0037 71.4516 32.6274 71.8436 33.0505C72.237 33.4734 72.8507 33.6071 73.3838 33.3842C78.8304 31.0978 85.25 35.0218 85.25 41.2984C85.25 46.0203 81.4003 49.8612 76.6684 49.8612C75.9084 49.8612 75.2934 50.4769 75.2934 51.2362C75.2934 51.9955 75.9084 52.6112 76.6684 52.6112C82.9162 52.6112 88 47.5362 88 41.2984C88 34.2935 81.7484 29.1642 75.1349 30.0788Z"
			fill="#415BF5"
		/>
		<path
			d="M64.6128 33.6216H23.3883C20.0421 33.6216 17.3203 36.3434 17.3203 39.6896V71.4709H13.7163C12.9563 71.4709 12.3413 72.0867 12.3413 72.8459C12.3413 76.0457 13.5847 79.0516 15.8447 81.3121C18.1193 83.5746 21.1285 84.8208 24.3162 84.8208H63.6849C70.2874 84.8208 75.6598 79.4491 75.6598 72.8459C75.6598 72.0867 75.0448 71.4709 74.2848 71.4709H70.6808V39.6896C70.6808 36.3434 67.959 33.6216 64.6128 33.6216ZM20.0703 39.6896C20.0703 37.86 21.5581 36.3716 23.3883 36.3716H64.6128C66.443 36.3716 67.9308 37.86 67.9308 39.6896V71.4709H50.7983C50.0383 71.4709 49.4233 72.0867 49.4233 72.8459V75.1508H38.5777V72.8459C38.5777 72.0867 37.9627 71.4709 37.2027 71.4709H20.0702V39.6896H20.0703ZM72.8078 74.2209C72.1417 78.6575 68.3041 82.0708 63.6849 82.0708H24.3162C21.8603 82.0708 19.54 81.1087 17.7863 79.3651C16.3764 77.9545 15.4795 76.1633 15.1921 74.2209H18.6955H35.828V76.5258C35.828 77.2851 36.443 77.9008 37.203 77.9008H50.7985C51.5585 77.9008 52.1735 77.2851 52.1735 76.5258V74.2209H69.3061H72.8078Z"
			fill="#415BF5"
		/>
		<path
			d="M44.0001 68.2001C49.2034 68.2001 53.4371 63.9671 53.4371 58.7638C53.4371 56.057 52.2846 53.6194 50.4522 51.897V46.104C50.4522 42.5539 47.5755 39.6433 44.0041 39.6433C40.4417 39.6433 37.5427 42.5417 37.5427 46.104V51.9024C35.7141 53.6244 34.5645 56.0597 34.5645 58.7636C34.5645 63.9671 38.7968 68.2001 44.0001 68.2001ZM40.2927 46.1042C40.2927 44.0577 41.9577 42.3934 44.0041 42.3934C46.0665 42.3934 47.7022 44.083 47.7022 46.1042V50.0864C46.5646 49.5994 45.3136 49.3281 44.0001 49.3281C42.6844 49.3281 41.4316 49.6005 40.2927 50.0892V46.1042ZM44.0001 52.0781C47.6873 52.0781 50.6871 55.0773 50.6871 58.7638C50.6871 62.4503 47.6873 65.4501 44.0001 65.4501C40.3141 65.4501 37.3145 62.4504 37.3145 58.7638C37.3145 55.0771 40.3141 52.0781 44.0001 52.0781Z"
			fill="#31C6B4"
		/>
		<path
			d="M44 61.6519C44.76 61.6519 45.375 61.0362 45.375 60.2769V57.251C45.375 56.4917 44.76 55.876 44 55.876C43.24 55.876 42.625 56.4917 42.625 57.251V60.2769C42.625 61.0363 43.24 61.6519 44 61.6519Z"
			fill="#31C6B4"
		/>
	</svg>
</template>

<script>
export default {
	name: 'PrivateIP',
};
</script>

<style></style>
