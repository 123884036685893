<template>
	<div class="tabs">
		<div class="tabs--container">
			<div
				v-for="tab in tabs"
				:key="tab.name"
				:class="{
					'tab-wrapper': true,
					active: isActiveTab(tab.value).value,
				}"
				@click="handleSelectTab(tab.value)"
			>
				<component :is="tab.icon" />
				<Text as="span" size="18" weight="400">{{ tab.name }}</Text>
				<div class="border" />
			</div>
		</div>
	</div>
</template>
<script>
import { ref, computed } from 'vue';

/** Features icons */
import Secure from '../../features-icons/Secure';
import Proto from '../../features-icons/Proto';
import Device from '../../features-icons/Device';
import Medal from '../../features-icons/Medal';

export default {
	name: 'Tabs',
	components: {
		Secure,
		Proto,
		Device,
		Medal,
	},
	props: {
		tabs: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Number,
			default: null,
		},
	},
	emits: ['on-change'],
	setup(props, { emit }) {
		const localValue = ref(0);

		const isControlled = props.value !== null;

		const isActiveTab = (tabValue) =>
			computed(() => {
				const value = isControlled ? props.value : localValue.value;

				return tabValue === value;
			});

		const handleSelectTab = (tabValue) => {
			if (!isControlled) {
				localValue.value = tabValue;
			}

			emit('on-change', tabValue);
		};

		return {
			isActiveTab,
			handleSelectTab,
		};
	},
};
</script>
<style lang="scss">
.tabs {
	width: 100%;
	margin-bottom: 67px;
	@media (max-width: 422px) {
		display: none;
	}
	&--container {
		display: flex;
		border-bottom: 1px solid #c5c5c5;
		.tab-wrapper {
			flex: 1 1 0%;
			padding: 21px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			.border {
				width: 100%;
				height: 2px;
				background-color: transparent;
				position: absolute;
				left: 0;
				bottom: -1px;
				transition: all 0.3s ease;
			}
			cursor: pointer;
			span {
				font-size: 18px;
				line-height: 23.4px;
				margin-left: 21px;
				color: #c5c5c5;
				transition: all 0.3s ease;
			}

			svg path {
				transition: all 0.3s ease;
			}

			&.active,
			&:hover {
				.border {
					background-color: #415bf5;
				}
				svg path {
					fill: #415bf5;
				}
				span {
					color: #000000;
				}
			}
		}
	}
}
</style>
