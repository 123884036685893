import { http } from '../../../http';

export class AuthService {
	async auth(type, value) {
		return await http.post('/auth', { type, value });
	}
	async authWithSessionId(sessionId, code) {
		return await http.patch(`/auth/${sessionId}`, { code });
	}
	async language(locale) {
		return await http.patch(`/profile/language`, {
      language: locale
    });
	}
}
