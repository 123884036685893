<template>
	<div :class="{ container: true, 'full-height': fullHeight }" v-bind="$attrs">
		<slot />
	</div>
</template>
<script>
export default {
	props: {
		fullHeight: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped lang="scss">
.container {
	max-width: 1171px;
  width: 100%;
	padding: 0 15px;
	margin: 0 auto;
	@media (max-width: 1024px) {
		width: 812px;
	}
	@media (max-width: 812px) {
		width: 768px;
	}
	@media (max-width: 769px) {
		width: 672px;
	}
	@media (min-width: 319px) and (max-width: 767px) {
		width: 100%;
	}
	&.full-height {
		height: 100%;
	}
}
</style>
