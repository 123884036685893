<template>
	<svg
		width="55"
		height="55"
		viewBox="0 0 55 55"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0)">
			<path
				d="M52.8784 10.5781C51.5876 10.041 50.1123 10.338 49.132 11.3233C48.0494 12.4057 46.7016 13.7535 41.25 13.7535C36.3405 13.7535 32.6378 16.6738 29.9337 18.8053C29.1717 19.4062 28.0639 20.279 27.6326 20.5525C27.2012 20.2454 26.4879 19.5438 25.9877 19.052C23.8392 16.9424 20.5931 13.7535 15.4687 13.7535C9.80894 13.7535 6.77092 12.2263 5.86788 11.3233C4.88095 10.338 3.4039 10.0409 2.12156 10.5781C0.837534 11.1101 -2.28882e-05 12.3637 -2.28882e-05 13.7535C-2.28882e-05 23.382 1.5257 36.5202 14.7201 39.454C20.0996 40.6488 22.05 42.0772 23.3391 43.0236C24.3512 43.7655 25.6117 44.6887 27.5 44.6887C29.3882 44.6887 30.6488 43.7639 31.6609 43.022C32.95 42.0772 34.9003 40.6472 40.2781 39.4523C53.4743 36.5219 55 23.382 55 13.7535C55 12.3637 54.1624 11.1101 52.8784 10.5781H52.8784ZM10.3125 24.2817C10.3125 24.2817 18.1476 23.0648 21.7708 26.3756C24.0625 28.4695 24.0625 30.5631 24.0625 32.657C10.3125 32.657 10.3125 24.2817 10.3125 24.2817L10.3125 24.2817ZM30.9375 32.657C30.9375 30.5631 30.9375 28.4695 33.2292 26.3756C36.8524 23.0648 44.6875 24.2817 44.6875 24.2817C44.6875 24.2817 44.6875 32.657 30.9375 32.657L30.9375 32.657Z"
				fill="#3C53DC"
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="55" height="55" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
