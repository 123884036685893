import * as atoms from './atoms';
import * as molecules from './molecules';
import * as organisms from './organisms';
import * as icons from './icons';

export default {
	...icons,
	...atoms,
	...molecules,
	...organisms,
};
