<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.54871 23.5266C3.13808 32.9157 6.57772 47.1642 12.561 55.8035C15.5476 60.1228 18.5713 63.9999 22.7129 63.9999C22.7909 63.9999 22.8692 63.9985 22.9493 63.9953C24.8951 63.9177 26.3013 63.3186 27.6608 62.7397C29.1871 62.0892 30.7657 61.4167 33.2418 61.4167C35.5974 61.4167 37.1007 62.0674 38.5544 62.6964C39.9872 63.3163 41.463 63.9576 43.6436 63.9166C48.3087 63.8298 51.1729 59.6461 53.7 55.9552C56.337 52.1016 57.6598 48.36 58.1061 46.9302L58.1242 46.8733C58.2091 46.6192 58.0891 46.3418 57.8455 46.23C57.8388 46.2268 57.814 46.2166 57.8071 46.2136C56.9856 45.8777 49.7794 42.6808 49.7036 33.9676C49.6334 26.8886 55.1097 23.1398 56.2079 22.4604L56.2591 22.4285C56.3781 22.3519 56.461 22.2304 56.4891 22.0915C56.5172 21.9528 56.4879 21.8084 56.4081 21.6918C52.6281 16.1601 46.8346 15.3264 44.502 15.2261C44.1636 15.1923 43.8142 15.1753 43.4633 15.1753C40.7237 15.1753 38.0993 16.21 35.9909 17.0414C34.5354 17.6153 33.2783 18.1109 32.4113 18.1109C31.437 18.1109 30.1727 17.6092 28.7087 17.0284C26.7509 16.2513 24.5319 15.3709 22.1828 15.3709C22.1268 15.3709 22.0716 15.3715 22.0172 15.3725C16.5552 15.4529 11.3941 18.5778 8.54871 23.5266Z"
      :fill="color ? color : '#415BF5'"
    />
    <path
      d="M44.5064 0.000403186C41.1987 0.135118 37.2309 2.17116 34.8593 4.94897C32.8439 7.28381 30.875 11.1767 31.3937 15.1006C31.4262 15.3461 31.626 15.5356 31.873 15.5547C32.0962 15.5721 32.3241 15.5809 32.5512 15.5812C35.7854 15.5812 39.2743 13.7919 41.657 10.9108C44.1647 7.86863 45.4328 3.96442 45.0492 0.467288C45.019 0.193243 44.7779 -0.0102985 44.5064 0.000403186Z"
      :fill="color ? color : '#415BF5'"
    />
  </svg>
</template>

<script>
export default {
  name: 'Apple',
  props: {
    color: String,
  },
};
</script>

<style></style>
