<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.012 0C5.35201 0 1.19459 3.34525 0.219779 7.90054C-0.267243 10.1767 0.0608405 12.597 1.14739 14.6565C2.19312 16.6386 3.90752 18.2405 5.95868 19.1484C8.09224 20.0929 10.5416 20.2575 12.7841 19.6134C14.9475 18.9922 16.8658 17.6288 18.1717 15.7985C20.9023 11.9722 20.5372 6.5975 17.3247 3.16893C15.442 1.15959 12.7678 0 10.012 0ZM14.8156 10.5352L12.0859 13.3272C11.3948 14.0343 10.3023 12.9631 10.9906 12.2594L12.368 10.8506H5.84056C5.37701 10.8506 4.99025 10.4641 4.99025 10.0014C4.99025 9.53865 5.37726 9.15212 5.84056 9.15212H12.3354L10.9307 7.74945C10.2327 7.05232 11.3149 5.97121 12.0129 6.66834L14.8093 9.46093C15.1057 9.75675 15.1085 10.2358 14.8156 10.5352Z"
			fill="#31C6B4"
		/>
	</svg>
</template>
