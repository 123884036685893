export class Strings {
	static RU = {
		submitThisCode: 'Submit this code',
		tryItForFree: 'попробовать',
		login: 'Вход',
		whatIsA: 'Что такое',
		vpn: 'впн',
	};

	static EN = {
		submitThisCode: 'Submit this code',
		tryItForFree: 'try it for free',
		login: 'Log in',
		whatIsA: 'What is a',
		vpn: 'vpn',
	};

	static getString(locale, key) {
		return Strings[locale][key];
	}
}
