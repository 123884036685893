<template>
	<svg
		width="15"
		height="11"
		viewBox="0 0 15 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.6515 0.451946C14.1874 -0.0127744 13.4337 -0.0124814 12.969 0.451946L5.3968 8.02444L2.03129 4.65895C1.56656 4.19423 0.813261 4.19423 0.34854 4.65895C-0.11618 5.12367 -0.11618 5.87698 0.34854 6.3417L4.55526 10.5484C4.78747 10.7806 5.09196 10.897 5.39648 10.897C5.701 10.897 6.00579 10.7809 6.238 10.5484L14.6515 2.13466C15.1162 1.67026 15.1162 0.916638 14.6515 0.451946Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	name: 'Check',
	inheritAttrs: false,
	props: {
		color: {
			type: String,
			default: '#31C6B4',
		},
	},
};
</script>
<style scoped lang="scss"></style>
