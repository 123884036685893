<template>
	<svg
		width="99"
		height="56"
		viewBox="0 0 99 56"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M28.2759 55.5123H23.4901C23.3519 55.5123 23.2397 55.4003 23.2397 55.2619V34.8166C23.2397 34.6783 23.3519 34.5662 23.4901 34.5662H28.2759C28.4142 34.5662 28.5263 34.6783 28.5263 34.8166V55.2621C28.5263 55.4003 28.4142 55.5123 28.2759 55.5123Z"
			fill="#415BF5"
		/>
		<path
			d="M71.1469 40.1552C71.1469 40.3402 70.9538 40.4622 70.7878 40.3805C70.1302 40.057 68.664 39.4481 66.6993 39.4481C63.2834 39.4481 60.9125 41.8282 60.9125 45.0366C60.9125 48.1494 63.1803 50.6274 66.6993 50.6274C68.6429 50.6274 70.1242 50.0241 70.7889 49.7017C70.9546 49.6214 71.1469 49.7434 71.1469 49.9276V54.9493C71.1469 55.0611 71.0731 55.1591 70.9656 55.1898C70.1901 55.4109 68.4908 55.8846 66.3311 55.8846C60.3845 55.8846 55.1663 51.7857 55.1663 45.0366C55.1663 38.7803 59.8882 34.1937 66.3311 34.1937C68.575 34.1937 70.2937 34.6881 70.9698 34.8916C71.0756 34.9235 71.1469 35.0206 71.1469 35.131V40.1552Z"
			fill="#415BF5"
		/>
		<path
			d="M15.9736 40.1545C15.9736 40.3396 15.7804 40.4616 15.6142 40.3796C14.958 40.0556 13.4952 39.4485 11.5332 39.4485C8.10941 39.4485 5.74006 41.8286 5.74006 45.037C5.74006 48.1498 8.01095 50.6278 11.5332 50.6278C13.4732 50.6278 14.9522 50.0265 15.6155 49.7037C15.7814 49.623 15.9736 49.7452 15.9736 49.9296V54.9503C15.9736 55.0619 15.9001 55.16 15.7929 55.1907C15.0232 55.4119 13.3246 55.8851 11.1539 55.8851C5.21206 55.8851 0 51.7862 0 45.0372C0 38.7809 4.72132 34.1943 11.1539 34.1943C13.4047 34.1943 15.123 34.6884 15.797 34.892C15.9026 34.9241 15.9738 35.021 15.9738 35.1314V40.1545H15.9736Z"
			fill="#415BF5"
		/>
		<path
			d="M98.8434 45.0367C98.8434 51.0287 94.2173 55.8848 87.7757 55.8848C81.3335 55.8848 76.7131 51.0287 76.7131 45.0367C76.7131 39.0534 81.3335 34.1938 87.7757 34.1938C94.2171 34.1937 98.8434 39.0532 98.8434 45.0367ZM87.7757 39.5273C84.6399 39.5273 82.3109 41.9946 82.3109 45.0367C82.3109 48.0852 84.6399 50.5503 87.7757 50.5503C90.9078 50.5503 93.2434 48.0852 93.2434 45.0367C93.2432 41.9946 90.9078 39.5273 87.7757 39.5273Z"
			fill="#415BF5"
		/>
		<path
			d="M48.5724 39.0013C48.5724 39.1646 48.4197 39.2841 48.2614 39.2442C47.5749 39.0706 45.8829 38.6874 44.4395 38.6874C42.3209 38.6874 41.1707 39.3969 41.1707 40.4048C41.1707 41.6805 42.7234 42.1235 43.5941 42.3988L45.0515 42.8635C48.4828 43.9543 50.0452 46.3039 50.0452 48.8555C50.0452 54.117 45.4186 55.8848 41.3734 55.8848C38.9108 55.8848 36.5861 55.4885 35.8712 55.3529C35.7532 55.3305 35.6681 55.2273 35.6681 55.1072V50.8179C35.6681 50.6536 35.8233 50.5335 35.982 50.5758C36.788 50.7909 38.69 51.2724 40.6601 51.2724C43.2893 51.2724 44.4989 50.5015 44.4989 49.3158C44.4989 48.254 43.4526 47.6408 42.1421 47.2289C41.8211 47.1223 41.3406 46.9717 41.013 46.8651C38.0683 45.9337 35.615 44.2041 35.615 40.737C35.615 36.819 38.5471 34.1875 43.42 34.1875C45.6376 34.1875 47.742 34.6512 48.3828 34.8072C48.4949 34.8344 48.5726 34.9346 48.5726 35.0501L48.5724 39.0013Z"
			fill="#415BF5"
		/>
		<path
			d="M4.30336 16.1587C4.30336 14.9741 3.3377 14.014 2.15197 14.014C0.967778 14.0138 0 14.9741 0 16.1587V20.6663C0 21.857 0.967778 22.8171 2.15197 22.8171C3.33751 22.8171 4.30336 21.857 4.30336 20.6663V16.1587Z"
			fill="#415BF5"
		/>
		<path
			d="M16.1227 10.2406C16.1227 9.05696 15.1565 8.09497 13.9713 8.09497C12.7858 8.09497 11.8193 9.05696 11.8193 10.2406V20.6662C11.8193 21.857 12.786 22.817 13.9713 22.817C15.1565 22.817 16.1227 21.857 16.1227 20.6662V10.2406Z"
			fill="#415BF5"
		/>
		<path
			d="M27.9423 2.14386C27.9423 0.960441 26.9753 0 25.7901 0C24.6052 0 23.6382 0.960441 23.6382 2.14386V24.9512C23.6382 26.141 24.6052 27.102 25.7901 27.102C26.9753 27.102 27.9423 26.1412 27.9423 24.9512V2.14386Z"
			fill="#415BF5"
		/>
		<path
			d="M51.5736 16.1587C51.5736 14.9741 50.608 14.014 49.4222 14.014C48.238 14.014 47.2703 14.9741 47.2703 16.1587V20.6663C47.2703 21.857 48.238 22.8171 49.4222 22.8171C50.6078 22.8171 51.5736 21.857 51.5736 20.6663V16.1587Z"
			fill="#415BF5"
		/>
		<path
			d="M39.7579 10.2404C39.7579 9.05683 38.7917 8.09485 37.6066 8.09485C36.421 8.09485 35.4546 9.05683 35.4546 10.2404V20.6661C35.4546 21.8569 36.4212 22.8169 37.6066 22.8169C38.7917 22.8169 39.7579 21.8569 39.7579 20.6661V10.2404Z"
			fill="#415BF5"
		/>
		<path
			d="M63.3893 10.2406C63.3893 9.05696 62.4231 8.09497 61.2379 8.09497C60.0524 8.09497 59.0859 9.05696 59.0859 10.2406V20.6662C59.0859 21.857 60.0526 22.817 61.2379 22.817C62.4231 22.817 63.3893 21.857 63.3893 20.6662V10.2406Z"
			fill="#415BF5"
		/>
		<path
			d="M75.2087 2.14386C75.2087 0.960441 74.2417 0 73.0565 0C71.8715 0 70.9045 0.960441 70.9045 2.14386V24.9512C70.9045 26.141 71.8715 27.102 73.0565 27.102C74.2417 27.102 75.2087 26.1412 75.2087 24.9512V2.14386Z"
			fill="#415BF5"
		/>
		<path
			d="M98.8402 16.1587C98.8402 14.9741 97.8746 14.014 96.6888 14.014C95.5046 14.014 94.5369 14.9741 94.5369 16.1587V20.6663C94.5369 21.857 95.5046 22.8171 96.6888 22.8171C97.8744 22.8171 98.8402 21.857 98.8402 20.6663V16.1587Z"
			fill="#415BF5"
		/>
		<path
			d="M87.0243 10.2404C87.0243 9.05683 86.0581 8.09485 84.8729 8.09485C83.6874 8.09485 82.7209 9.05683 82.7209 10.2404V20.6661C82.7209 21.8569 83.6876 22.8169 84.8729 22.8169C86.0581 22.8169 87.0243 21.8569 87.0243 20.6661V10.2404Z"
			fill="#415BF5"
		/>
	</svg>
</template>

<script>
export default {
	name: 'Cisco',
};
</script>

<style></style>
