<template>
	<div v-if="open" class="header-login-form">
		<button class="mobile-close" @click="$emit('onClose')">&times;</button>
		<div class="form">
			<form @submit.prevent="authStore.auth">
				<Text as="h4" weight="400" size="16">{{ translate.text2 }}</Text>
				<Divider size="11" />
				<InputField
					v-focus
					:disabled="
						authStore.state.loading || authStore.state.awaitingForConfirmed
					"
					v-model="authStore.state.email"
					placeholder="E-Mail"
					autocomplete="email"
				/>
				<Divider size="9" />
				<InputField
					v-if="authStore.state.awaitingForConfirmed"
					v-focus
					v-model="authStore.state.code"
					:placeholder="translate.text3"
				/>
				<Divider size="9" />
				<!-- <Text as="span" weight="400" size="14" color="#6B6B6B">
					Forgot Password?
					<Text as="router-link" to="#" color="#31C6B4"> Reset here </Text>
				</Text> -->
				<Divider size="17" />
				<Button
					variant="primary"
					fullWidth
					:disabled="authStore.state.loading"
					:loading="authStore.state.loading"
				>
					{{ btnText }}
				</Button>
				<!-- <Divider size="26" />
				<Text as="p" weight="400" size="14" color="#6B6B6B" align="center">
					If you don't already have an account,
					<Text as="router-link" to="#" color="#415BF5"> Register Now </Text>
				</Text> -->
			</form>
		</div>
	</div>
</template>

<script>
import { computed, watch, inject } from 'vue';
import { useAuthStore } from '../../../store/use';

export default {
	props: {
		open: Boolean,
	},
	emits: ['onClose'],
	setup(props) {
		const translate = inject('translate');
		const authStore = useAuthStore();

		const isOpen = computed(() => props.open);

		watch(isOpen, () => {
			if (!isOpen.value) {
				authStore.reset();
			}
		});

		return {
			translate,
			btnText: computed(() => {
				if (authStore.state.awaitingForConfirmed) {
					return translate.button8;
				}

				return translate.button7;
			}),

			authStore,
		};
	},
};
</script>

<style scoped lang="scss">
.header-login-form {
	width: 286px;
	background-color: #fff;
	border-radius: 5px;
	position: absolute;
	top: 55px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 99;
	filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
	.mobile-close {
		position: absolute;
		top: 5px;
		right: 10px;
		font-size: 22px;
		display: none;
		padding: 0;
		@media (min-width: 319px) and (max-width: 767px) {
			display: block;
		}
	}
	@media (min-width: 319px) and (max-width: 767px) {
		position: fixed;
		top: 100px;
		left: 50%;
		transform: translateX(-50%);
		filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
	}
	.form {
		width: 100%;
		height: 100%;
		padding: 24px 20.5px;
		padding-bottom: 31px;
	}
	&:before {
		content: '';
		display: block;
		position: inherit;
		width: 25px;
		height: 25px;
		background-color: #fff;
		border-radius: 3px;
		right: 50%;
		transform: translate(50%, -40%) rotate(-45deg);
		@media (min-width: 319px) and (max-width: 767px) {
			display: none;
		}
	}
}
</style>
