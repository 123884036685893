<template>
  <div></div>
</template>

<script>
import { defineComponent, watch } from 'vue';
import { ratesStore } from '../../../store/modules/RatesStore';

const RETURN_URL = `${process.env.VUE_APP_DVPN_LK}/?successful_payment=true`;

export default defineComponent({
	setup() {
		const initYookassa = (billCt) => {
			//Инициализация виджета. Все параметры обязательные.
			const checkout = new window.YooMoneyCheckoutWidget({
				confirmation_token: billCt, //Токен, который вы получили после создания платежа
				return_url: RETURN_URL, //Ссылка на страницу завершения оплаты
				error_callback: function (error) {
					console.log(error);
				},
				customization: {
					modal: true,
					colors: {
						controlPrimary: '#415bf5',
					},
				},
			});

			//Отображение платежной формы в контейнере
			checkout.render();
		};

		watch(ratesStore.state, (state) => {
			if (state.paymentData?.bill_ct) {
				initYookassa(state.paymentData.bill_ct);
			}
		});
	},
});
</script>

<style scoped lang="scss">
.fake {
	position: absolute;
	left: -9999px;
}
</style>
