<template>
	<svg
		width="64"
		height="76"
		viewBox="0 0 64 76"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.67576 24.4705C3.37412 24.4705 2.26972 24.9244 1.36189 25.8322C0.454053 26.74 0 27.8294 0 29.1009V48.6227C0 49.926 0.453916 51.0294 1.36189 51.9374C2.26972 52.8452 3.37412 53.2993 4.67576 53.2993C5.97645 53.2993 7.07416 52.8453 7.96792 51.9374C8.85991 51.0295 9.30713 49.926 9.30713 48.6227V29.1009C9.30713 27.8293 8.85321 26.74 7.94524 25.8322C7.03727 24.9244 5.94722 24.4705 4.67576 24.4705Z"
			:fill="color ? color : '#415BF5'"
		/>
		<path
			d="M42.2677 6.94559L45.4912 0.999186C45.7028 0.60551 45.6275 0.303628 45.2647 0.091354C44.8709 -0.0916878 44.5683 0.00106245 44.3569 0.363048L41.0884 6.35698C38.2123 5.08539 35.1704 4.44843 31.9626 4.44843C28.7542 4.44843 25.712 5.08553 22.837 6.35698L19.5684 0.363048C19.356 0.00106245 19.0534 -0.0908682 18.6605 0.091354C18.2969 0.304584 18.2217 0.60551 18.4341 0.999186L21.6576 6.94559C18.389 8.61249 15.7857 10.934 13.8487 13.9151C11.9118 16.8979 10.9429 20.1574 10.9429 23.6994H52.9368C52.9368 20.1582 51.9677 16.8986 50.0309 13.9151C48.0938 10.934 45.5055 8.61249 42.2677 6.94559ZM23.6312 15.5947C23.2826 15.9441 22.8663 16.118 22.3823 16.118C21.8974 16.118 21.4895 15.9441 21.1567 15.5947C20.824 15.2469 20.6576 14.8322 20.6576 14.3466C20.6576 13.8627 20.824 13.4471 21.1567 13.0985C21.4895 12.7507 21.8983 12.5769 22.3823 12.5769C22.8663 12.5769 23.2826 12.7507 23.6312 13.0985C23.979 13.4479 24.1537 13.8627 24.1537 14.3466C24.1528 14.8314 23.9782 15.2469 23.6312 15.5947ZM42.7667 15.5947C42.4333 15.9441 42.0245 16.118 41.5413 16.118C41.0562 16.118 40.64 15.9441 40.2921 15.5947C39.9437 15.2469 39.7698 14.8322 39.7698 14.3466C39.7698 13.8627 39.9437 13.4471 40.2921 13.0985C40.64 12.7507 41.0562 12.5769 41.5413 12.5769C42.0253 12.5769 42.4332 12.7507 42.7667 13.0985C43.0998 13.4479 43.2659 13.8627 43.2659 14.3466C43.2659 14.8314 43.0996 15.2469 42.7667 15.5947Z"
			:fill="color ? color : '#415BF5'"
		/>
		<path
			d="M11.1228 55.5686C11.1228 56.9622 11.6068 58.1419 12.5748 59.1099C13.5437 60.078 14.7234 60.562 16.116 60.562H19.4758L19.5218 70.8689C19.5218 72.1704 19.9757 73.2756 20.8836 74.1836C21.7914 75.0915 22.8816 75.5454 24.1522 75.5454C25.4531 75.5454 26.5581 75.0915 27.4661 74.1836C28.3741 73.2756 28.828 72.1705 28.828 70.8689V60.5629H35.0929V70.8689C35.0929 72.1704 35.5467 73.2756 36.4547 74.1836C37.3627 75.0915 38.4668 75.5454 39.7686 75.5454C41.0694 75.5454 42.1745 75.0915 43.0824 74.1836C43.9904 73.2756 44.4442 72.1705 44.4442 70.8689V60.5629H47.8493C49.2111 60.5629 50.3756 60.0788 51.3454 59.1107C52.3134 58.1427 52.7976 56.963 52.7976 55.5696V25.332H11.1228V55.5686Z"
			:fill="color ? color : '#415BF5'"
		/>
		<path
			d="M59.247 24.4705C57.9754 24.4705 56.8862 24.9177 55.9782 25.8097C55.0704 26.7033 54.6165 27.801 54.6165 29.1009V48.6227C54.6165 49.926 55.0702 51.0294 55.9782 51.9374C56.8862 52.8453 57.9762 53.2993 59.247 53.2993C60.5478 53.2993 61.6529 52.8453 62.5609 51.9374C63.4688 51.0294 63.9226 49.926 63.9226 48.6227V29.1009C63.9226 27.8009 63.4688 26.7033 62.5609 25.8097C61.6529 24.9177 60.5478 24.4705 59.247 24.4705Z"
			:fill="color ? color : '#415BF5'"
		/>
	</svg>
</template>

<script>
export default {
	name: 'Android',
	props: {
		color: String,
	},
};
</script>

<style></style>
