import { reactive, readonly } from 'vue';
import { ratesService } from '../../services/rates';
import { successPaidMessage, errorPaidMessage } from '../../../helpers/notification';

const state = reactive({
	currentRate: null,
	paymentData: null,
	loading: false,
});

const actions = {
	setCurrentRate(rate) {
		this.reset();
		state.currentRate = rate;
	},

	reset() {
		state.currentRate = null;
	},

	async makeSubscribe() {
		if (state.currentRate === null) return;

		state.loading = true;

		try {
			state.paymentData = null;

			const { data } = await ratesService.makeSubscribe(state.currentRate);

			if (data.data.bill_status === 'paid') {
				successPaidMessage();
			}

			state.paymentData = data.data;
			if (state.paymentData.bill_ct) {
				localStorage.setItem('cart_payment', state.paymentData.bill_ct);
			} else {
				localStorage.setItem('cart_payment', state.paymentData.bill_id);
			}
		} catch (error) {
			errorPaidMessage();
		} finally {
			state.loading = false;
		}
	},
};

export const ratesStore = {
	state: readonly(state),
	...actions,
};
