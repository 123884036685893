<template>
	<component
		:is="as"
		:style="{
			'font-size': `${size}px`,
			'font-weight': weight,
			'line-height': `${lineHeight}px`,
			color,
			'text-align': align,
		}"
		v-bind="$attrs"
	>
		<slot />
	</component>
</template>
<script>
export default {
	name: 'Text',
	props: {
		as: String,
		size: [String, Number],
		weight: [String, Number],
		color: String,
		align: String,
		lineHeight: [String, Number],
	},
	setup(props, context) {
		return {};
	},
};
</script>
<style scoped lang="scss">
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}
a {
	text-decoration: none;
}
</style>
