import { reactive, readonly } from 'vue';

const state = reactive({
	messages: [],
});

const actions = {
	addMessage(title, text) {
		const message = {
			id: state.messages.length + 1,
			title,
			text,
		};

		state.messages.push(message);

		this.autoRemove(message.id);
	},

	removeMessage(id) {
		state.messages = state.messages.filter((message) => message.id !== id);
	},

	autoRemove(id) {
		const timer = setTimeout(() => {
			this.removeMessage(id);

			clearTimeout(timer);
		}, 2500);
	},
};

export const alertStore = {
	state: readonly(state),
	...actions,
};
