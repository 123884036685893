<script>
import { alertStore } from '../../../store/modules/Alert';

export default {
	setup() {
		return {
			alertStore,
		};
	},
};
</script>
<template>
	<div class="alert-wrapper">
		<div
			v-for="message in alertStore.state.messages"
			:key="message.id"
			class="alert-box"
		>
			<div class="content">
				<span class="title">{{ message.title }}</span
				>&nbsp;
				<span class="text">{{ message.text }}</span>
			</div>

			<button @click="alertStore.removeMessage(message.id)">&times;</button>
		</div>
	</div>
</template>
<style scoped lang="scss">
.alert-wrapper {
	position: absolute;
	top: 10px;
	left: 50%;
	transform: translate(-50%);
	z-index: 999;
}
.alert-box {
	width: auto;
	min-width: 560px;
	background: #31c6b4;
	border-radius: 8px;
	padding: 16px 10px;
	color: #fff;
	display: flex;
	justify-content: space-between;
	&:not(:last-child) {
		margin-bottom: 15px;
	}
	button {
		outline: none;
		border: none;
		background: none;
		color: #fff;
		cursor: pointer;
		font-size: 25px;
	}
	.title {
		font-weight: 600;
	}
}
</style>
